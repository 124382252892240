/* stylelint-disable property-no-vendor-prefix, scss/at-rule-no-unknown */
*, ::before, ::after {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x:  ;
  --tw-pan-y:  ;
  --tw-pinch-zoom:  ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position:  ;
  --tw-gradient-via-position:  ;
  --tw-gradient-to-position:  ;
  --tw-ordinal:  ;
  --tw-slashed-zero:  ;
  --tw-numeric-figure:  ;
  --tw-numeric-spacing:  ;
  --tw-numeric-fraction:  ;
  --tw-ring-inset:  ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(51 0 255 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur:  ;
  --tw-brightness:  ;
  --tw-contrast:  ;
  --tw-grayscale:  ;
  --tw-hue-rotate:  ;
  --tw-invert:  ;
  --tw-saturate:  ;
  --tw-sepia:  ;
  --tw-drop-shadow:  ;
  --tw-backdrop-blur:  ;
  --tw-backdrop-brightness:  ;
  --tw-backdrop-contrast:  ;
  --tw-backdrop-grayscale:  ;
  --tw-backdrop-hue-rotate:  ;
  --tw-backdrop-invert:  ;
  --tw-backdrop-opacity:  ;
  --tw-backdrop-saturate:  ;
  --tw-backdrop-sepia:  ;
  --tw-contain-size:  ;
  --tw-contain-layout:  ;
  --tw-contain-paint:  ;
  --tw-contain-style:  ;
}
::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x:  ;
  --tw-pan-y:  ;
  --tw-pinch-zoom:  ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position:  ;
  --tw-gradient-via-position:  ;
  --tw-gradient-to-position:  ;
  --tw-ordinal:  ;
  --tw-slashed-zero:  ;
  --tw-numeric-figure:  ;
  --tw-numeric-spacing:  ;
  --tw-numeric-fraction:  ;
  --tw-ring-inset:  ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(51 0 255 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur:  ;
  --tw-brightness:  ;
  --tw-contrast:  ;
  --tw-grayscale:  ;
  --tw-hue-rotate:  ;
  --tw-invert:  ;
  --tw-saturate:  ;
  --tw-sepia:  ;
  --tw-drop-shadow:  ;
  --tw-backdrop-blur:  ;
  --tw-backdrop-brightness:  ;
  --tw-backdrop-contrast:  ;
  --tw-backdrop-grayscale:  ;
  --tw-backdrop-hue-rotate:  ;
  --tw-backdrop-invert:  ;
  --tw-backdrop-opacity:  ;
  --tw-backdrop-saturate:  ;
  --tw-backdrop-sepia:  ;
  --tw-contain-size:  ;
  --tw-contain-layout:  ;
  --tw-contain-paint:  ;
  --tw-contain-style:  ;
}
.container {
  width: 100%;
}
@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}
@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}
@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}
@media (min-width: 1440px) {
  .container {
    max-width: 1440px;
  }
}
.blueGradient1 {
  background-image: linear-gradient(to bottom, var(--tw-gradient-stops));
  --tw-gradient-from: #30f var(--tw-gradient-from-position);
  --tw-gradient-to: rgb(51 0 255 / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  --tw-gradient-from-position: 30%;
  --tw-gradient-to: rgb(113 180 255 / 0)  var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #71b4ff var(--tw-gradient-via-position), var(--tw-gradient-to);
  --tw-gradient-via-position: 144%;
  --tw-gradient-to: #ddf3ff var(--tw-gradient-to-position);
  --tw-gradient-to-position: 169%;
}
.blueGradient2 {
  background: linear-gradient(189.74deg, #30f 5.88%, #8a6ae2 125%, #d2e2fe 135.36%);
}
.purpleBlueGradient1 {
  background: linear-gradient(81.78deg, #8a6ae2 4.23%, #3b6af7 117.8%);
}
.bgShadowPrimaryHighlightBlue {
  position: relative;
  background: var(--color-blue-50);
}
.bgShadowPrimaryHighlightBlue::before {
  content: "";
  position: absolute;
  left: -21.18%;
  right: 88.15%;
  top: -26.6%;
  bottom: 31%;
  background: linear-gradient(180deg, rgba(25, 56, 219, 0.2) 24.21%, rgba(211, 86, 111, 0.2) 100%);
  filter: blur(75px);
  transform: rotate(-7.42deg);
}
.bgShadowPrimaryHighlightWhiteBlue {
  position: relative;
  background: #f8f9ff;
}
.bgShadowPrimaryHighlightWhiteBlue::before {
  content: "";
  position: absolute;
  left: 45.19%;
  right: 11.45%;
  top: 9.49%;
  bottom: 1.81%;
  background: linear-gradient(180deg, rgba(25, 56, 219, 0.2) 14.68%, rgba(33, 161, 255, 0.2) 73.99%, rgba(254, 206, 47, 0.2) 98.44%);
  filter: blur(76.3648px);
  transform: rotate(-7.42deg);
}
#acx-website .visible {
  visibility: visible;
}
#acx-website .static {
  position: static;
}
#acx-website .fixed {
  position: fixed;
}
#acx-website .absolute {
  position: absolute;
}
#acx-website .relative {
  position: relative;
}
#acx-website .sticky {
  position: sticky;
}
#acx-website .inset-0 {
  inset: 0px;
}
#acx-website .-bottom-5 {
  bottom: -1.25rem;
}
#acx-website .-left-\[0\%\] {
  left: -0%;
}
#acx-website .bottom-0 {
  bottom: 0px;
}
#acx-website .bottom-\[0\%\] {
  bottom: 0%;
}
#acx-website .left-2\/4 {
  left: 50%;
}
#acx-website .left-\[0\%\] {
  left: 0%;
}
#acx-website .left-\[11\%\] {
  left: 11%;
}
#acx-website .left-\[45\%\] {
  left: 45%;
}
#acx-website .right-2 {
  right: 0.5rem;
}
#acx-website .right-\[-165px\] {
  right: -165px;
}
#acx-website .top-0 {
  top: 0px;
}
#acx-website .top-2 {
  top: 0.5rem;
}
#acx-website .top-\[56\%\] {
  top: 56%;
}
#acx-website .top-\[68\%\] {
  top: 68%;
}
#acx-website .top-\[70\%\] {
  top: 70%;
}
#acx-website .top-\[79\%\] {
  top: 79%;
}
#acx-website .top-\[80\%\] {
  top: 80%;
}
#acx-website .top-\[81\%\] {
  top: 81%;
}
#acx-website .isolate {
  isolation: isolate;
}
#acx-website .-z-10 {
  z-index: -10;
}
#acx-website .z-10 {
  z-index: 10;
}
#acx-website .z-50 {
  z-index: 50;
}
#acx-website .z-\[1\] {
  z-index: 1;
}
#acx-website .z-\[2\] {
  z-index: 2;
}
#acx-website .z-\[9\] {
  z-index: 9;
}
#acx-website .order-1 {
  order: 1;
}
#acx-website .order-2 {
  order: 2;
}
#acx-website .col-span-1 {
  grid-column: span 1 / span 1;
}
#acx-website .col-span-2 {
  grid-column: span 2 / span 2;
}
#acx-website .col-span-3 {
  grid-column: span 3 / span 3;
}
#acx-website .col-span-6 {
  grid-column: span 6 / span 6;
}
#acx-website .col-start-1 {
  grid-column-start: 1;
}
#acx-website .m-0 {
  margin: 0px;
}
#acx-website .m-\[30px\] {
  margin: 30px;
}
#acx-website .m-auto {
  margin: auto;
}
#acx-website .mx-0 {
  margin-left: 0px;
  margin-right: 0px;
}
#acx-website .mx-4 {
  margin-left: 1rem;
  margin-right: 1rem;
}
#acx-website .mx-auto {
  margin-left: auto;
  margin-right: auto;
}
#acx-website .my-0 {
  margin-top: 0px;
  margin-bottom: 0px;
}
#acx-website .my-1 {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}
#acx-website .my-2 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
#acx-website .my-3 {
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
}
#acx-website .my-8 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}
#acx-website .my-\[60px\] {
  margin-top: 60px;
  margin-bottom: 60px;
}
#acx-website .-mt-10 {
  margin-top: -2.5rem;
}
#acx-website .mb-0 {
  margin-bottom: 0px;
}
#acx-website .mb-1 {
  margin-bottom: 0.25rem;
}
#acx-website .mb-12 {
  margin-bottom: 3rem;
}
#acx-website .mb-16 {
  margin-bottom: 4rem;
}
#acx-website .mb-2 {
  margin-bottom: 0.5rem;
}
#acx-website .mb-3 {
  margin-bottom: 0.75rem;
}
#acx-website .mb-4 {
  margin-bottom: 1rem;
}
#acx-website .mb-5 {
  margin-bottom: 1.25rem;
}
#acx-website .mb-6 {
  margin-bottom: 1.5rem;
}
#acx-website .mb-8 {
  margin-bottom: 2rem;
}
#acx-website .mb-\[37\.6px\] {
  margin-bottom: 37.6px;
}
#acx-website .mb-\[52px\] {
  margin-bottom: 52px;
}
#acx-website .mb-\[6px\] {
  margin-bottom: 6px;
}
#acx-website .ml-2 {
  margin-left: 0.5rem;
}
#acx-website .ml-\[-176px\] {
  margin-left: -176px;
}
#acx-website .ml-\[-80px\] {
  margin-left: -80px;
}
#acx-website .ml-\[50px\] {
  margin-left: 50px;
}
#acx-website .mr-0 {
  margin-right: 0px;
}
#acx-website .mr-10 {
  margin-right: 2.5rem;
}
#acx-website .mr-2 {
  margin-right: 0.5rem;
}
#acx-website .mr-4 {
  margin-right: 1rem;
}
#acx-website .mr-\[90px\] {
  margin-right: 90px;
}
#acx-website .ms-0 {
  margin-inline-start: 0px;
}
#acx-website .mt-0 {
  margin-top: 0px;
}
#acx-website .mt-1 {
  margin-top: 0.25rem;
}
#acx-website .mt-10 {
  margin-top: 2.5rem;
}
#acx-website .mt-12 {
  margin-top: 3rem;
}
#acx-website .mt-2 {
  margin-top: 0.5rem;
}
#acx-website .mt-3 {
  margin-top: 0.75rem;
}
#acx-website .mt-4 {
  margin-top: 1rem;
}
#acx-website .mt-6 {
  margin-top: 1.5rem;
}
#acx-website .mt-7 {
  margin-top: 1.75rem;
}
#acx-website .mt-8 {
  margin-top: 2rem;
}
#acx-website .mt-\[-24px\] {
  margin-top: -24px;
}
#acx-website .mt-\[-35px\] {
  margin-top: -35px;
}
#acx-website .mt-\[-70px\] {
  margin-top: -70px;
}
#acx-website .mt-\[-72px\] {
  margin-top: -72px;
}
#acx-website .mt-\[16px_\!important\] {
  margin-top: 16px !important;
}
#acx-website .mt-\[8px_\!important\] {
  margin-top: 8px !important;
}
#acx-website .box-border {
  box-sizing: border-box;
}
#acx-website .\!block {
  display: block !important;
}
#acx-website .block {
  display: block;
}
#acx-website .inline-block {
  display: inline-block;
}
#acx-website .inline {
  display: inline;
}
#acx-website .flex {
  display: flex;
}
#acx-website .table {
  display: table;
}
#acx-website .\!grid {
  display: grid !important;
}
#acx-website .grid {
  display: grid;
}
#acx-website .contents {
  display: contents;
}
#acx-website .list-item {
  display: list-item;
}
#acx-website .hidden {
  display: none;
}
#acx-website .aspect-square {
  aspect-ratio: 1 / 1;
}
#acx-website .h-0 {
  height: 0px;
}
#acx-website .h-7 {
  height: 1.75rem;
}
#acx-website .h-\[100px\] {
  height: 100px;
}
#acx-website .h-\[130px\] {
  height: 130px;
}
#acx-website .h-\[146px\] {
  height: 146px;
}
#acx-website .h-\[204px\] {
  height: 204px;
}
#acx-website .h-\[20px\] {
  height: 20px;
}
#acx-website .h-\[210px\] {
  height: 210px;
}
#acx-website .h-\[211px\] {
  height: 211px;
}
#acx-website .h-\[250px\] {
  height: 250px;
}
#acx-website .h-\[28px\] {
  height: 28px;
}
#acx-website .h-\[345px\] {
  height: 345px;
}
#acx-website .h-\[37px\] {
  height: 37px;
}
#acx-website .h-\[38px\] {
  height: 38px;
}
#acx-website .h-\[400px\] {
  height: 400px;
}
#acx-website .h-\[47px\] {
  height: 47px;
}
#acx-website .h-\[485px\] {
  height: 485px;
}
#acx-website .h-\[52px\] {
  height: 52px;
}
#acx-website .h-\[56\] {
  height: 56;
}
#acx-website .h-\[57px\] {
  height: 57px;
}
#acx-website .h-\[60px\] {
  height: 60px;
}
#acx-website .h-\[63px\] {
  height: 63px;
}
#acx-website .h-\[70px\] {
  height: 70px;
}
#acx-website .h-\[72px\] {
  height: 72px;
}
#acx-website .h-\[930px\] {
  height: 930px;
}
#acx-website .h-\[inherit\] {
  height: inherit;
}
#acx-website .h-\[initial\] {
  height: initial;
}
#acx-website .h-auto {
  height: auto;
}
#acx-website .h-full {
  height: 100%;
}
#acx-website .max-h-\[220px\] {
  max-height: 220px;
}
#acx-website .max-h-\[250px\] {
  max-height: 250px;
}
#acx-website .max-h-\[50px\] {
  max-height: 50px;
}
#acx-website .max-h-\[520px\] {
  max-height: 520px;
}
#acx-website .max-h-\[53px\] {
  max-height: 53px;
}
#acx-website .max-h-\[content\] {
  max-height: content;
}
#acx-website .max-h-\[inherit_\!important\] {
  max-height: inherit !important;
}
#acx-website .max-h-full {
  max-height: 100%;
}
#acx-website .min-h-\[1140px\] {
  min-height: 1140px;
}
#acx-website .min-h-\[150px\] {
  min-height: 150px;
}
#acx-website .min-h-\[280px\] {
  min-height: 280px;
}
#acx-website .min-h-\[30vh\] {
  min-height: 30vh;
}
#acx-website .min-h-\[45px\] {
  min-height: 45px;
}
#acx-website .min-h-\[520px\] {
  min-height: 520px;
}
#acx-website .min-h-\[65vh\] {
  min-height: 65vh;
}
#acx-website .min-h-\[790px\] {
  min-height: 790px;
}
#acx-website .min-h-\[auto\] {
  min-height: auto;
}
#acx-website .min-h-\[inherit_\!important\] {
  min-height: inherit !important;
}
#acx-website .min-h-full {
  min-height: 100%;
}
#acx-website .w-0 {
  width: 0px;
}
#acx-website .w-4\/12 {
  width: 33.333333%;
}
#acx-website .w-8\/12 {
  width: 66.666667%;
}
#acx-website .w-\[100px\] {
  width: 100px;
}
#acx-website .w-\[118px\] {
  width: 118px;
}
#acx-website .w-\[11px\] {
  width: 11px;
}
#acx-website .w-\[134px\] {
  width: 134px;
}
#acx-website .w-\[150px\] {
  width: 150px;
}
#acx-website .w-\[158px\] {
  width: 158px;
}
#acx-website .w-\[165px\] {
  width: 165px;
}
#acx-website .w-\[192px\] {
  width: 192px;
}
#acx-website .w-\[271px\] {
  width: 271px;
}
#acx-website .w-\[293px\] {
  width: 293px;
}
#acx-website .w-\[315px\] {
  width: 315px;
}
#acx-website .w-\[335px\] {
  width: 335px;
}
#acx-website .w-\[345px\] {
  width: 345px;
}
#acx-website .w-\[350px\] {
  width: 350px;
}
#acx-website .w-\[410px\] {
  width: 410px;
}
#acx-website .w-\[433px\] {
  width: 433px;
}
#acx-website .w-\[70px\] {
  width: 70px;
}
#acx-website .w-\[72px\] {
  width: 72px;
}
#acx-website .w-\[80\%\] {
  width: 80%;
}
#acx-website .w-\[inherit\] {
  width: inherit;
}
#acx-website .w-\[initial\] {
  width: initial;
}
#acx-website .w-full {
  width: 100%;
}
#acx-website .min-w-\[105px\] {
  min-width: 105px;
}
#acx-website .min-w-\[150px\] {
  min-width: 150px;
}
#acx-website .min-w-\[24px\] {
  min-width: 24px;
}
#acx-website .min-w-\[250px\] {
  min-width: 250px;
}
#acx-website .min-w-\[280px\] {
  min-width: 280px;
}
#acx-website .min-w-\[293px\] {
  min-width: 293px;
}
#acx-website .min-w-\[320px\] {
  min-width: 320px;
}
#acx-website .min-w-\[350px\] {
  min-width: 350px;
}
#acx-website .min-w-\[380px\] {
  min-width: 380px;
}
#acx-website .min-w-\[4px\] {
  min-width: 4px;
}
#acx-website .min-w-\[53px\] {
  min-width: 53px;
}
#acx-website .min-w-full {
  min-width: 100%;
}
#acx-website .min-w-max {
  min-width: -moz-max-content;
  min-width: max-content;
}
#acx-website .max-w-2xl {
  max-width: 42rem;
}
#acx-website .max-w-5xl {
  max-width: 64rem;
}
#acx-website .max-w-\[1000vh\] {
  max-width: 1000vh;
}
#acx-website .max-w-\[100px\] {
  max-width: 100px;
}
#acx-website .max-w-\[24px\] {
  max-width: 24px;
}
#acx-website .max-w-\[274px\] {
  max-width: 274px;
}
#acx-website .max-w-\[280px\] {
  max-width: 280px;
}
#acx-website .max-w-\[315px\] {
  max-width: 315px;
}
#acx-website .max-w-\[335px\] {
  max-width: 335px;
}
#acx-website .max-w-\[375px\] {
  max-width: 375px;
}
#acx-website .max-w-\[380px\] {
  max-width: 380px;
}
#acx-website .max-w-\[388px\] {
  max-width: 388px;
}
#acx-website .max-w-\[390px\] {
  max-width: 390px;
}
#acx-website .max-w-\[420px\] {
  max-width: 420px;
}
#acx-website .max-w-\[425px\] {
  max-width: 425px;
}
#acx-website .max-w-\[447px\] {
  max-width: 447px;
}
#acx-website .max-w-\[500px\] {
  max-width: 500px;
}
#acx-website .max-w-\[509px\] {
  max-width: 509px;
}
#acx-website .max-w-\[520px\] {
  max-width: 520px;
}
#acx-website .max-w-\[53px\] {
  max-width: 53px;
}
#acx-website .max-w-\[600px\] {
  max-width: 600px;
}
#acx-website .max-w-\[803px\] {
  max-width: 803px;
}
#acx-website .max-w-\[808px\] {
  max-width: 808px;
}
#acx-website .max-w-\[830px\] {
  max-width: 830px;
}
#acx-website .max-w-full {
  max-width: 100%;
}
#acx-website .max-w-max {
  max-width: -moz-max-content;
  max-width: max-content;
}
#acx-website .max-w-md {
  max-width: 28rem;
}
#acx-website .max-w-sm {
  max-width: 24rem;
}
#acx-website .max-w-xs {
  max-width: 20rem;
}
#acx-website .flex-shrink {
  flex-shrink: 1;
}
#acx-website .flex-grow {
  flex-grow: 1;
}
#acx-website .-translate-x-2\/4 {
  --tw-translate-x: -50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
#acx-website .-translate-x-8 {
  --tw-translate-x: -2rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
#acx-website .-translate-y-px {
  --tw-translate-y: -1px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
#acx-website .translate-x-6 {
  --tw-translate-x: 1.5rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
#acx-website .translate-y-2\/4 {
  --tw-translate-y: 50%;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
#acx-website .rotate-\[372deg\] {
  --tw-rotate: 372deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
#acx-website .scale-125 {
  --tw-scale-x: 1.25;
  --tw-scale-y: 1.25;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
#acx-website .transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
@keyframes fade-up {
  from {
    bottom: -10px;
    opacity: 0;
  }
  to {
    bottom: -20px;
    opacity: 1;
  }
}
#acx-website .animate-fade-up {
  animation: fade-up 0.5s ease-in-out;
}
#acx-website .cursor-pointer {
  cursor: pointer;
}
#acx-website .resize {
  resize: both;
}
#acx-website .list-none {
  list-style-type: none;
}
#acx-website .grid-cols-11 {
  grid-template-columns: repeat(11, minmax(0, 1fr));
}
#acx-website .grid-cols-5 {
  grid-template-columns: repeat(5, minmax(0, 1fr));
}
#acx-website .grid-cols-6 {
  grid-template-columns: repeat(6, minmax(0, 1fr));
}
#acx-website .flex-row {
  flex-direction: row;
}
#acx-website .flex-col {
  flex-direction: column;
}
#acx-website .flex-col-reverse {
  flex-direction: column-reverse;
}
#acx-website .flex-wrap {
  flex-wrap: wrap;
}
#acx-website .place-items-center {
  place-items: center;
}
#acx-website .content-center {
  align-content: center;
}
#acx-website .items-start {
  align-items: flex-start;
}
#acx-website .items-end {
  align-items: flex-end;
}
#acx-website .items-center {
  align-items: center;
}
#acx-website .items-stretch {
  align-items: stretch;
}
#acx-website .justify-start {
  justify-content: flex-start;
}
#acx-website .justify-end {
  justify-content: flex-end;
}
#acx-website .justify-center {
  justify-content: center;
}
#acx-website .justify-between {
  justify-content: space-between;
}
#acx-website .justify-around {
  justify-content: space-around;
}
#acx-website .gap-0 {
  gap: 0px;
}
#acx-website .gap-1 {
  gap: 0.25rem;
}
#acx-website .gap-2 {
  gap: 0.5rem;
}
#acx-website .gap-4 {
  gap: 1rem;
}
#acx-website .gap-\[23px\] {
  gap: 23px;
}
#acx-website .self-center {
  align-self: center;
}
#acx-website .justify-self-center {
  justify-self: center;
}
#acx-website .overflow-hidden {
  overflow: hidden;
}
#acx-website .overflow-x-hidden {
  overflow-x: hidden;
}
#acx-website .whitespace-normal {
  white-space: normal;
}
#acx-website .whitespace-nowrap {
  white-space: nowrap;
}
#acx-website .text-wrap {
  text-wrap: wrap;
}
#acx-website .rounded-3xl {
  border-radius: 1.5rem;
}
#acx-website .rounded-\[0_0_10px_10px\] {
  border-radius: 0 0 10px 10px;
}
#acx-website .rounded-\[10px\] {
  border-radius: 10px;
}
#acx-website .rounded-\[10px_10px_0_0\] {
  border-radius: 10px 10px 0 0;
}
#acx-website .rounded-\[10px_10px_0px_0px\] {
  border-radius: 10px 10px 0px 0px;
}
#acx-website .rounded-\[8px\] {
  border-radius: 8px;
}
#acx-website .rounded-\[8px_8px_0_0\] {
  border-radius: 8px 8px 0 0;
}
#acx-website .rounded-full {
  border-radius: 9999px;
}
#acx-website .rounded-lg {
  border-radius: 50%;
}
#acx-website .rounded-md {
  border-radius: 8px;
}
#acx-website .rounded-xl {
  border-radius: 0.75rem;
}
#acx-website .rounded-bl-\[10px\] {
  border-bottom-left-radius: 10px;
}
#acx-website .rounded-br-\[20px\] {
  border-bottom-right-radius: 20px;
}
#acx-website .rounded-tl-\[10px\] {
  border-top-left-radius: 10px;
}
#acx-website .rounded-tr-\[0\] {
  border-top-right-radius: 0;
}
#acx-website .border {
  border-width: 1px;
}
#acx-website .border-0 {
  border-width: 0px;
}
#acx-website .border-x-0 {
  border-left-width: 0px;
  border-right-width: 0px;
}
#acx-website .border-b {
  border-bottom-width: 1px;
}
#acx-website .border-b-0 {
  border-bottom-width: 0px;
}
#acx-website .border-b-2 {
  border-bottom-width: 2px;
}
#acx-website .border-b-\[1px\] {
  border-bottom-width: 1px;
}
#acx-website .border-l {
  border-left-width: 1px;
}
#acx-website .border-r {
  border-right-width: 1px;
}
#acx-website .border-t {
  border-top-width: 1px;
}
#acx-website .border-t-0 {
  border-top-width: 0px;
}
#acx-website .border-solid {
  border-style: solid;
}
#acx-website .border-none {
  border-style: none;
}
#acx-website .border-\[\#94bbfe\] {
  --tw-border-opacity: 1;
  border-color: rgb(148 187 254 / var(--tw-border-opacity, 1));
}
#acx-website .border-\[\#c0cbd8\] {
  --tw-border-opacity: 1;
  border-color: rgb(192 203 216 / var(--tw-border-opacity, 1));
}
#acx-website .border-\[none\] {
  border-color: none;
}
#acx-website .border-gray-200 {
  --tw-border-opacity: 1;
  border-color: rgb(229 231 235 / var(--tw-border-opacity, 1));
}
#acx-website .border-gray-300 {
  --tw-border-opacity: 1;
  border-color: rgb(209 213 219 / var(--tw-border-opacity, 1));
}
#acx-website .border-neutral-150 {
  --tw-border-opacity: 1;
  border-color: rgb(192 203 216 / var(--tw-border-opacity, 1));
}
#acx-website .border-neutral-300 {
  --tw-border-opacity: 1;
  border-color: rgb(92 112 138 / var(--tw-border-opacity, 1));
}
#acx-website .border-neutral-75 {
  --tw-border-opacity: 1;
  border-color: rgb(231 236 243 / var(--tw-border-opacity, 1));
}
#acx-website .border-x-\[\#c0cbd8\] {
  --tw-border-opacity: 1;
  border-left-color: rgb(192 203 216 / var(--tw-border-opacity, 1));
  border-right-color: rgb(192 203 216 / var(--tw-border-opacity, 1));
}
#acx-website .border-b-\[\#c0cbd8\] {
  --tw-border-opacity: 1;
  border-bottom-color: rgb(192 203 216 / var(--tw-border-opacity, 1));
}
#acx-website .border-b-blue-50 {
  --tw-border-opacity: 1;
  border-bottom-color: rgb(239 245 255 / var(--tw-border-opacity, 1));
}
#acx-website .border-b-gray-300 {
  --tw-border-opacity: 1;
  border-bottom-color: rgb(209 213 219 / var(--tw-border-opacity, 1));
}
#acx-website .border-t-\[\#c0cbd8\] {
  --tw-border-opacity: 1;
  border-top-color: rgb(192 203 216 / var(--tw-border-opacity, 1));
}
#acx-website .border-t-neutral-150 {
  --tw-border-opacity: 1;
  border-top-color: rgb(192 203 216 / var(--tw-border-opacity, 1));
}
#acx-website .bg-\[\#068255\] {
  --tw-bg-opacity: 1;
  background-color: rgb(6 130 85 / var(--tw-bg-opacity, 1));
}
#acx-website .bg-\[\#154199\] {
  --tw-bg-opacity: 1;
  background-color: rgb(21 65 153 / var(--tw-bg-opacity, 1));
}
#acx-website .bg-\[\#2c47f6\] {
  --tw-bg-opacity: 1;
  background-color: rgb(44 71 246 / var(--tw-bg-opacity, 1));
}
#acx-website .bg-\[\#30f\] {
  --tw-bg-opacity: 1;
  background-color: rgb(51 0 255 / var(--tw-bg-opacity, 1));
}
#acx-website .bg-\[\#3300ff\] {
  --tw-bg-opacity: 1;
  background-color: rgb(51 0 255 / var(--tw-bg-opacity, 1));
}
#acx-website .bg-\[\#33b083\] {
  --tw-bg-opacity: 1;
  background-color: rgb(51 176 131 / var(--tw-bg-opacity, 1));
}
#acx-website .bg-\[\#3b6af7\] {
  --tw-bg-opacity: 1;
  background-color: rgb(59 106 247 / var(--tw-bg-opacity, 1));
}
#acx-website .bg-\[\#C0CBD8\] {
  --tw-bg-opacity: 1;
  background-color: rgb(192 203 216 / var(--tw-bg-opacity, 1));
}
#acx-website .bg-\[\#c0cbd8\] {
  --tw-bg-opacity: 1;
  background-color: rgb(192 203 216 / var(--tw-bg-opacity, 1));
}
#acx-website .bg-\[\#d2e2fe\] {
  --tw-bg-opacity: 1;
  background-color: rgb(210 226 254 / var(--tw-bg-opacity, 1));
}
#acx-website .bg-\[\#eff2f8\] {
  --tw-bg-opacity: 1;
  background-color: rgb(239 242 248 / var(--tw-bg-opacity, 1));
}
#acx-website .bg-\[\#eff5ff\] {
  --tw-bg-opacity: 1;
  background-color: rgb(239 245 255 / var(--tw-bg-opacity, 1));
}
#acx-website .bg-\[\#f5f9ff\] {
  --tw-bg-opacity: 1;
  background-color: rgb(245 249 255 / var(--tw-bg-opacity, 1));
}
#acx-website .bg-\[\#f8f9fc\] {
  --tw-bg-opacity: 1;
  background-color: rgb(248 249 252 / var(--tw-bg-opacity, 1));
}
#acx-website .bg-\[\#fbcb5b\] {
  --tw-bg-opacity: 1;
  background-color: rgb(251 203 91 / var(--tw-bg-opacity, 1));
}
#acx-website .bg-\[\#fbcb5c\] {
  --tw-bg-opacity: 1;
  background-color: rgb(251 203 92 / var(--tw-bg-opacity, 1));
}
#acx-website .bg-\[\#ffffff\] {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
}
#acx-website .bg-blue-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(210 226 254 / var(--tw-bg-opacity, 1));
}
#acx-website .bg-blue-25 {
  --tw-bg-opacity: 1;
  background-color: rgb(245 249 255 / var(--tw-bg-opacity, 1));
}
#acx-website .bg-blue-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(51 0 255 / var(--tw-bg-opacity, 1));
}
#acx-website .bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity, 1));
}
#acx-website .bg-neutral-25 {
  --tw-bg-opacity: 1;
  background-color: rgb(248 249 252 / var(--tw-bg-opacity, 1));
}
#acx-website .bg-orange-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(249 115 22 / var(--tw-bg-opacity, 1));
}
#acx-website .bg-transparent {
  background-color: transparent;
}
#acx-website .bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
}
#acx-website .bg-\[linear-gradient\(90deg\2c \#dff7fd_0\%\2c \#e4dafb_68\.43\%\2c \#ddf3ff_95\.43\%\)\] {
  background-image: linear-gradient(90deg,#dff7fd 0%,#e4dafb 68.43%,#ddf3ff 95.43%);
}
#acx-website .bg-gradient-to-b {
  background-image: linear-gradient(to bottom, var(--tw-gradient-stops));
}
#acx-website .from-\[\#30f\] {
  --tw-gradient-from: #30f var(--tw-gradient-from-position);
  --tw-gradient-to: rgb(51 0 255 / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}
#acx-website .from-30\% {
  --tw-gradient-from-position: 30%;
}
#acx-website .via-\[\#71b4ff\] {
  --tw-gradient-to: rgb(113 180 255 / 0)  var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #71b4ff var(--tw-gradient-via-position), var(--tw-gradient-to);
}
#acx-website .via-\[144\%\] {
  --tw-gradient-via-position: 144%;
}
#acx-website .to-\[\#ddf3ff\] {
  --tw-gradient-to: #ddf3ff var(--tw-gradient-to-position);
}
#acx-website .to-\[169\%\] {
  --tw-gradient-to-position: 169%;
}
#acx-website .bg-contain {
  background-size: contain;
}
#acx-website .bg-cover {
  background-size: cover;
}
#acx-website .bg-\[position\:right_bottom\] {
  background-position: right bottom;
}
#acx-website .bg-no-repeat {
  background-repeat: no-repeat;
}
#acx-website .object-contain {
  -o-object-fit: contain;
     object-fit: contain;
}
#acx-website .object-cover {
  -o-object-fit: cover;
     object-fit: cover;
}
#acx-website .object-\[center_bottom\] {
  -o-object-position: center bottom;
     object-position: center bottom;
}
#acx-website .object-\[center_top\] {
  -o-object-position: center top;
     object-position: center top;
}
#acx-website .object-bottom {
  -o-object-position: bottom;
     object-position: bottom;
}
#acx-website .object-center {
  -o-object-position: center;
     object-position: center;
}
#acx-website .object-left {
  -o-object-position: left;
     object-position: left;
}
#acx-website .object-left-bottom {
  -o-object-position: left bottom;
     object-position: left bottom;
}
#acx-website .object-left-top {
  -o-object-position: left top;
     object-position: left top;
}
#acx-website .object-right {
  -o-object-position: right;
     object-position: right;
}
#acx-website .object-right-bottom {
  -o-object-position: right bottom;
     object-position: right bottom;
}
#acx-website .object-right-top {
  -o-object-position: right top;
     object-position: right top;
}
#acx-website .object-top {
  -o-object-position: top;
     object-position: top;
}
#acx-website .p-0 {
  padding: 0px;
}
#acx-website .p-1 {
  padding: 0.25rem;
}
#acx-website .p-2 {
  padding: 0.5rem;
}
#acx-website .p-3 {
  padding: 0.75rem;
}
#acx-website .p-4 {
  padding: 1rem;
}
#acx-website .p-5 {
  padding: 1.25rem;
}
#acx-website .p-6 {
  padding: 1.5rem;
}
#acx-website .p-8 {
  padding: 2rem;
}
#acx-website .px-0 {
  padding-left: 0px;
  padding-right: 0px;
}
#acx-website .px-1 {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}
#acx-website .px-12 {
  padding-left: 3rem;
  padding-right: 3rem;
}
#acx-website .px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
#acx-website .px-3 {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
#acx-website .px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}
#acx-website .px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
#acx-website .px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}
#acx-website .px-\[10px\] {
  padding-left: 10px;
  padding-right: 10px;
}
#acx-website .px-\[40px\] {
  padding-left: 40px;
  padding-right: 40px;
}
#acx-website .py-0 {
  padding-top: 0px;
  padding-bottom: 0px;
}
#acx-website .py-1 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}
#acx-website .py-10 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}
#acx-website .py-12 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}
#acx-website .py-16 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}
#acx-website .py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
#acx-website .py-3 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
#acx-website .py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
#acx-website .py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}
#acx-website .py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}
#acx-website .py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}
#acx-website .py-\[10px\] {
  padding-top: 10px;
  padding-bottom: 10px;
}
#acx-website .py-\[48px\] {
  padding-top: 48px;
  padding-bottom: 48px;
}
#acx-website .\!pl-0 {
  padding-left: 0px !important;
}
#acx-website .pb-0 {
  padding-bottom: 0px;
}
#acx-website .pb-10 {
  padding-bottom: 2.5rem;
}
#acx-website .pb-12 {
  padding-bottom: 3rem;
}
#acx-website .pb-16 {
  padding-bottom: 4rem;
}
#acx-website .pb-2 {
  padding-bottom: 0.5rem;
}
#acx-website .pb-3 {
  padding-bottom: 0.75rem;
}
#acx-website .pb-4 {
  padding-bottom: 1rem;
}
#acx-website .pb-5 {
  padding-bottom: 1.25rem;
}
#acx-website .pb-6 {
  padding-bottom: 1.5rem;
}
#acx-website .pb-8 {
  padding-bottom: 2rem;
}
#acx-website .pb-\[336px\] {
  padding-bottom: 336px;
}
#acx-website .pl-0 {
  padding-left: 0px;
}
#acx-website .pl-4 {
  padding-left: 1rem;
}
#acx-website .pr-8 {
  padding-right: 2rem;
}
#acx-website .pt-0 {
  padding-top: 0px;
}
#acx-website .pt-1 {
  padding-top: 0.25rem;
}
#acx-website .pt-10 {
  padding-top: 2.5rem;
}
#acx-website .pt-12 {
  padding-top: 3rem;
}
#acx-website .pt-2 {
  padding-top: 0.5rem;
}
#acx-website .pt-4 {
  padding-top: 1rem;
}
#acx-website .pt-5 {
  padding-top: 1.25rem;
}
#acx-website .pt-6 {
  padding-top: 1.5rem;
}
#acx-website .pt-8 {
  padding-top: 2rem;
}
#acx-website .pt-\[246px\] {
  padding-top: 246px;
}
#acx-website .pt-\[336px\] {
  padding-top: 336px;
}
#acx-website .text-left {
  text-align: left;
}
#acx-website .text-center {
  text-align: center;
}
#acx-website .text-right {
  text-align: right;
}
#acx-website .text-justify {
  text-align: justify;
}
#acx-website .font-ultramarine {
  font-family: var(--font-ultramarine);
}
#acx-website .text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}
#acx-website .text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}
#acx-website .text-xs {
  font-size: 0.75rem;
  line-height: 1rem;
}
#acx-website .font-black {
  font-weight: 900;
}
#acx-website .font-bold {
  font-weight: 700;
}
#acx-website .font-medium {
  font-weight: 500;
}
#acx-website .font-normal {
  font-weight: 400;
}
#acx-website .font-semibold {
  font-weight: 600;
}
#acx-website .uppercase {
  text-transform: uppercase;
}
#acx-website .italic {
  font-style: italic;
}
#acx-website .leading-7 {
  line-height: 1.75rem;
}
#acx-website .leading-\[0\] {
  line-height: 0;
}
#acx-website .leading-\[140\%\] {
  line-height: 140%;
}
#acx-website .leading-\[16\.6px\] {
  line-height: 16.6px;
}
#acx-website .leading-\[16\.8px\] {
  line-height: 16.8px;
}
#acx-website .leading-\[170\%\] {
  line-height: 170%;
}
#acx-website .tracking-\[0\.25em\] {
  letter-spacing: 0.25em;
}
#acx-website .tracking-\[3\.5px\] {
  letter-spacing: 3.5px;
}
#acx-website .tracking-eyebrow {
  letter-spacing: 0.25em;
}
#acx-website .text-\[\#008000\] {
  --tw-text-opacity: 1;
  color: rgb(0 128 0 / var(--tw-text-opacity, 1));
}
#acx-website .text-\[\#154199\] {
  --tw-text-opacity: 1;
  color: rgb(21 65 153 / var(--tw-text-opacity, 1));
}
#acx-website .text-\[\#1d252f\] {
  --tw-text-opacity: 1;
  color: rgb(29 37 47 / var(--tw-text-opacity, 1));
}
#acx-website .text-\[\#30f\] {
  --tw-text-opacity: 1;
  color: rgb(51 0 255 / var(--tw-text-opacity, 1));
}
#acx-website .text-\[\#3300FF\] {
  --tw-text-opacity: 1;
  color: rgb(51 0 255 / var(--tw-text-opacity, 1));
}
#acx-website .text-\[\#3300ff\] {
  --tw-text-opacity: 1;
  color: rgb(51 0 255 / var(--tw-text-opacity, 1));
}
#acx-website .text-\[\#3b6af7\] {
  --tw-text-opacity: 1;
  color: rgb(59 106 247 / var(--tw-text-opacity, 1));
}
#acx-website .text-\[\#42546B\] {
  --tw-text-opacity: 1;
  color: rgb(66 84 107 / var(--tw-text-opacity, 1));
}
#acx-website .text-\[\#42546b\] {
  --tw-text-opacity: 1;
  color: rgb(66 84 107 / var(--tw-text-opacity, 1));
}
#acx-website .text-\[\#5c708a\] {
  --tw-text-opacity: 1;
  color: rgb(92 112 138 / var(--tw-text-opacity, 1));
}
#acx-website .text-\[\#c0cbd8\] {
  --tw-text-opacity: 1;
  color: rgb(192 203 216 / var(--tw-text-opacity, 1));
}
#acx-website .text-\[\#fff\] {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
}
#acx-website .text-black {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity, 1));
}
#acx-website .text-blue-300 {
  --tw-text-opacity: 1;
  color: rgb(59 106 247 / var(--tw-text-opacity, 1));
}
#acx-website .text-blue-800 {
  --tw-text-opacity: 1;
  color: rgb(20 46 87 / var(--tw-text-opacity, 1));
}
#acx-website .text-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity, 1));
}
#acx-website .text-gray-600 {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity, 1));
}
#acx-website .text-gray-900 {
  --tw-text-opacity: 1;
  color: rgb(17 24 39 / var(--tw-text-opacity, 1));
}
#acx-website .text-neutral-300 {
  --tw-text-opacity: 1;
  color: rgb(92 112 138 / var(--tw-text-opacity, 1));
}
#acx-website .text-neutral-500 {
  --tw-text-opacity: 1;
  color: rgb(66 84 107 / var(--tw-text-opacity, 1));
}
#acx-website .text-neutral-900 {
  --tw-text-opacity: 1;
  color: rgb(29 37 47 / var(--tw-text-opacity, 1));
}
#acx-website .text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
}
#acx-website .underline {
  text-decoration-line: underline;
}
#acx-website .no-underline {
  text-decoration-line: none;
}
#acx-website .decoration-white {
  text-decoration-color: #fff;
}
#acx-website .opacity-100 {
  opacity: 1;
}
#acx-website .opacity-5 {
  opacity: 0.05;
}
#acx-website .shadow {
  --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
#acx-website .shadow-\[0_0\.5px_3px_0_\#10182817\] {
  --tw-shadow: 0 0.5px 3px 0 #10182817;
  --tw-shadow-colored: 0 0.5px 3px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
#acx-website .shadow-\[0_1\.6553px_3\.3106px_0_rgb\(16_24_40_\/_5\%\)\] {
  --tw-shadow: 0 1.6553px 3.3106px 0 rgb(16 24 40 / 5%);
  --tw-shadow-colored: 0 1.6553px 3.3106px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
#acx-website .shadow-\[0_2px_10px_0_rgb\(0_0_0_\/_10\%\)\] {
  --tw-shadow: 0 2px 10px 0 rgb(0 0 0 / 10%);
  --tw-shadow-colored: 0 2px 10px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
#acx-website .shadow-inner {
  --tw-shadow: inset 0 2px 4px 0 rgb(0 0 0 / 0.05);
  --tw-shadow-colored: inset 0 2px 4px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
#acx-website .shadow-md {
  --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
#acx-website .shadow-none {
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
#acx-website .outline {
  outline-style: solid;
}
#acx-website .blur {
  --tw-blur: blur(8px);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
#acx-website .drop-shadow {
  --tw-drop-shadow: drop-shadow(0 1px 2px rgb(0 0 0 / 0.1)) drop-shadow(0 1px 1px rgb(0 0 0 / 0.06));
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
#acx-website .invert {
  --tw-invert: invert(100%);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
#acx-website .filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
#acx-website .transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}
#acx-website .transition-\[top\] {
  transition-property: top;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}
#acx-website .delay-\[0\.05s\] {
  transition-delay: 0.05s;
}
#acx-website .duration-\[0\.05s\] {
  transition-duration: 0.05s;
}
#acx-website .ease-in-out {
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
#acx-website .ease-linear {
  transition-timing-function: linear;
}
#acx-website .ease-out {
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
}
#acx-website .\[background\:linear-gradient\(172\.38deg\2c _\#30f_30\.04\%\2c _\#71b4ff_144\.48\%\2c _\#ddf3ff_169\.51\%\)\] {
  background: linear-gradient(172.38deg, #30f 30.04%, #71b4ff 144.48%, #ddf3ff 169.51%);
}
#acx-website .\[background\:linear-gradient\(180\.09deg\2c \#30f_-19\.14\%\2c \#68d6f2_121\.73\%\)\] {
  background: linear-gradient(180.09deg,#30f -19.14%,#68d6f2 121.73%);
}
#acx-website .\[background\:linear-gradient\(185deg\2c \#30f_4\.22\%\2c \#586afc_71\.44\%\2c \#b1d6ff_127\.36\%\)\] {
  background: linear-gradient(185deg,#30f 4.22%,#586afc 71.44%,#b1d6ff 127.36%);
}
#acx-website .\[background\:linear-gradient\(_344\.37deg\2c rgb\(0_0_0_\/_72\.9\%\)_0\%\2c rgb\(0_0_0_\/_39\.2\%\)_73\.79\%\2c rgb\(0_0_0_\/_0\%\)_101\.87\%_\)\] {
  background: linear-gradient( 344.37deg,rgb(0 0 0 / 72.9%) 0%,rgb(0 0 0 / 39.2%) 73.79%,rgb(0 0 0 / 0%) 101.87% );
}
#acx-website .\[background\:none\] {
  background: none;
}
#acx-website .\[content-visibility\:auto\] {
  content-visibility: auto;
}
#acx-website .\[overflow\:initial\] {
  overflow: initial;
}
#acx-website .\[transform\:inherit\] {
  transform: inherit;
}
#acx-website .\[transform\:none\] {
  transform: none;
}

/**
// overwrite transitions for the home-hero carousel to avoid bugs on safari.
// can't use inline CSS with vanilla js because -webkit and -moz don't quite work on safari with this approach
*/

.main-home-hero-section ul,
.main-home-hero-section li {
  transition: all 1.2s ease !important;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

body {
  color: #1d252f; /* var.$color-content-primary; */
  font-family: var(--font-ultramarine);
}

/* bold */
/* @font-face {
  font-family: Ultramarine;
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('Ultramarine'), url('/next-assets/fonts/Ultramarine-Bold.woff2') format('woff2'),
    local('Ultramarine'), url('/next-assets/fonts/Ultramarine-Bold.woff') format('woff');
} */

/* bold italic */
/* @font-face {
  font-family: Ultramarine;
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: local('Ultramarine'), url('/next-assets/fonts/Ultramarine-BoldItalic.woff2') format('woff2'),
    local('Ultramarine'), url('/next-assets/fonts/Ultramarine-BoldItalic.woff') format('woff');
} */

/* italic */
/* @font-face {
  font-family: Ultramarine;
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: local('Ultramarine'), url('/next-assets/fonts/Ultramarine-Italic.woff2') format('woff2'),
    url('/next-assets/fonts/Ultramarine-Italic.woff') format('woff');
} */

/* medium */
/* @font-face {
  font-family: Ultramarine;
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local('Ultramarine'), url('/next-assets/fonts/Ultramarine-Medium.woff2') format('woff2'),
    local('Ultramarine'), url('/next-assets/fonts/Ultramarine-Medium.woff') format('woff');
} */

/* medium italic */
/* @font-face {
  font-family: Ultramarine;
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: local('Ultramarine'),
    url('/next-assets/fonts/Ultramarine-MediumItalic.woff2') format('woff2'), local('Ultramarine'),
    url('/next-assets/fonts/Ultramarine-MediumItalic.woff') format('woff');
} */

/* regular */
/* @font-face {
  font-family: Ultramarine;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Ultramarine'), url('/next-assets/fonts/Ultramarine-Regular.woff2') format('woff2'),
    local('Ultramarine'), url('/next-assets/fonts/Ultramarine-Regular.woff') format('woff');
} */

/* footer { */

/* TODO: Update Footer component to support custom styling passing a className to apply those styles. */
footer a[data-testid='achieve-logo'] {
  width: 66px !important;
  height: 54px !important;
  overflow: hidden;
}

footer a[data-testid='achieve-logo'] img {
  width: 215px !important;
  min-width: inherit !important;
  max-width: inherit !important;
}

/* stylelint-enable property-no-vendor-prefix */

#tcb-container {
  height: 340px;
}

@media screen and (min-width: 480px) {
  #tcb-container {
    height: 228px;
  }
}

@media screen and (min-width: 768px) {
  #tcb-container {
    height: 160px;
  }
}

@media screen and (min-width: 1024px) {
  #tcb-container {
    height: 130px;
  }
}

.data-\[variant\=\"blueGradient1\"\]\:blueGradient1[data-variant="blueGradient1"] {
  background-image: linear-gradient(to bottom, var(--tw-gradient-stops));
  --tw-gradient-from: #30f var(--tw-gradient-from-position);
  --tw-gradient-to: rgb(51 0 255 / 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
  --tw-gradient-from-position: 30%;
  --tw-gradient-to: rgb(113 180 255 / 0)  var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), #71b4ff var(--tw-gradient-via-position), var(--tw-gradient-to);
  --tw-gradient-via-position: 144%;
  --tw-gradient-to: #ddf3ff var(--tw-gradient-to-position);
  --tw-gradient-to-position: 169%;
}

.data-\[variant\=\"purpleBlueGradient1\"\]\:purpleBlueGradient1[data-variant="purpleBlueGradient1"] {
  background: linear-gradient(81.78deg, #8a6ae2 4.23%, #3b6af7 117.8%);
}

.data-\[variant\=\"bgShadowPrimaryHighlightBlue\"\]\:bgShadowPrimaryHighlightBlue[data-variant="bgShadowPrimaryHighlightBlue"] {
  position: relative;
  background: var(--color-blue-50);
}

.data-\[variant\=\"bgShadowPrimaryHighlightBlue\"\]\:bgShadowPrimaryHighlightBlue[data-variant="bgShadowPrimaryHighlightBlue"]::before {
  content: "";
  position: absolute;
  left: -21.18%;
  right: 88.15%;
  top: -26.6%;
  bottom: 31%;
  background: linear-gradient(180deg, rgba(25, 56, 219, 0.2) 24.21%, rgba(211, 86, 111, 0.2) 100%);
  filter: blur(75px);
  transform: rotate(-7.42deg);
}

.data-\[variant\=\"bgShadowPrimaryHighlightWhiteBlue\"\]\:bgShadowPrimaryHighlightWhiteBlue[data-variant="bgShadowPrimaryHighlightWhiteBlue"] {
  position: relative;
  background: #f8f9ff;
}

.data-\[variant\=\"bgShadowPrimaryHighlightWhiteBlue\"\]\:bgShadowPrimaryHighlightWhiteBlue[data-variant="bgShadowPrimaryHighlightWhiteBlue"]::before {
  content: "";
  position: absolute;
  left: 45.19%;
  right: 11.45%;
  top: 9.49%;
  bottom: 1.81%;
  background: linear-gradient(180deg, rgba(25, 56, 219, 0.2) 14.68%, rgba(33, 161, 255, 0.2) 73.99%, rgba(254, 206, 47, 0.2) 98.44%);
  filter: blur(76.3648px);
  transform: rotate(-7.42deg);
}

#acx-website .before\:absolute::before {
  content: var(--tw-content);
  position: absolute;
}

#acx-website .before\:inset-y-0::before {
  content: var(--tw-content);
  top: 0px;
  bottom: 0px;
}

#acx-website .before\:bottom-full::before {
  content: var(--tw-content);
  bottom: 100%;
}

#acx-website .before\:left-2\/4::before {
  content: var(--tw-content);
  left: 50%;
}

#acx-website .before\:top-0::before {
  content: var(--tw-content);
  top: 0px;
}

#acx-website .before\:ml-\[-1\.5px\]::before {
  content: var(--tw-content);
  margin-left: -1.5px;
}

#acx-website .before\:w-\[3px\]::before {
  content: var(--tw-content);
  width: 3px;
}

#acx-website .before\:bg-\[\#3300ff\]::before {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(51 0 255 / var(--tw-bg-opacity, 1));
}

#acx-website .before\:content-\[\"_\"\]::before {
  --tw-content: " ";
  content: var(--tw-content);
}

#acx-website .after\:mt-2::after {
  content: var(--tw-content);
  margin-top: 0.5rem;
}

#acx-website .after\:block::after {
  content: var(--tw-content);
  display: block;
}

#acx-website .after\:min-h-\[50\%\]::after {
  content: var(--tw-content);
  min-height: 50%;
}

#acx-website .after\:min-w-\[4px\]::after {
  content: var(--tw-content);
  min-width: 4px;
}

#acx-website .after\:content-\[\'\'\]::after {
  --tw-content: '';
  content: var(--tw-content);
}

#acx-website .first\:pt-4:first-child {
  padding-top: 1rem;
}

#acx-website .last\:border-b-0:last-child {
  border-bottom-width: 0px;
}

#acx-website .hover\:cursor-pointer:hover {
  cursor: pointer;
}

#acx-website .hover\:bg-\[\#F5F9FF\]:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(245 249 255 / var(--tw-bg-opacity, 1));
}

#acx-website .hover\:bg-blue-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(21 65 153 / var(--tw-bg-opacity, 1));
}

#acx-website .hover\:bg-orange-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(128 51 25 / var(--tw-bg-opacity, 1));
}

#acx-website .hover\:text-\[\#42546b\]:hover {
  --tw-text-opacity: 1;
  color: rgb(66 84 107 / var(--tw-text-opacity, 1));
}

#acx-website .hover\:underline:hover {
  text-decoration-line: underline;
}

#acx-website .hover\:no-underline:hover {
  text-decoration-line: none;
}

#acx-website .active\:bg-\[\#F5F9FF\]:active {
  --tw-bg-opacity: 1;
  background-color: rgb(245 249 255 / var(--tw-bg-opacity, 1));
}

#acx-website :is(.group\/item:hover .group-hover\/item\:z-\[1\]) {
  z-index: 1;
}

#acx-website :is(.group\/item:hover .group-hover\/item\:rounded-3xl) {
  border-radius: 1.5rem;
}

#acx-website :is(.group\/item:hover .group-hover\/item\:bg-\[\#3300ff\]) {
  --tw-bg-opacity: 1;
  background-color: rgb(51 0 255 / var(--tw-bg-opacity, 1));
}

#acx-website :is(.group\/item:hover .group-hover\/item\:bg-white) {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
}

#acx-website :is(.group\/item:hover .group-hover\/item\:font-bold) {
  font-weight: 700;
}

#acx-website :is(.group\/item:hover .group-hover\/item\:text-\[\#3300ff\]) {
  --tw-text-opacity: 1;
  color: rgb(51 0 255 / var(--tw-text-opacity, 1));
}

#acx-website .data-\[graph\=\"card2\"\]\:h-7[data-graph="card2"] {
  height: 1.75rem;
}

#acx-website .data-\[graph\=\"card3\"\]\:h-\[57px\][data-graph="card3"] {
  height: 57px;
}

#acx-website .data-\[variant\=\"awards\"\]\:max-h-\[50px\][data-variant="awards"] {
  max-height: 50px;
}

#acx-website .data-\[variant-progress\=\"true\"\]\:min-h-\[1140px\][data-variant-progress="true"] {
  min-height: 1140px;
}

#acx-website .data-\[variant\=\"awards\"\]\:min-w-\[24px\][data-variant="awards"] {
  min-width: 24px;
}

#acx-website .data-\[variant\=\"awards\"\]\:max-w-\[24px\][data-variant="awards"] {
  max-width: 24px;
}

#acx-website .data-\[container-color\=\"blue\"\]\:rounded-\[10px\][data-container-color="blue"] {
  border-radius: 10px;
}

#acx-website .data-\[container-color\=\"blue\"\]\:bg-\[\#3300ff\][data-container-color="blue"] {
  --tw-bg-opacity: 1;
  background-color: rgb(51 0 255 / var(--tw-bg-opacity, 1));
}

#acx-website .data-\[inverse\=\"false\"\]\:bg-\[\#f5f9ff\][data-inverse="false"] {
  --tw-bg-opacity: 1;
  background-color: rgb(245 249 255 / var(--tw-bg-opacity, 1));
}

#acx-website .data-\[inverse\=\"true\"\]\:bg-\[\#f5f9ff\][data-inverse="true"] {
  --tw-bg-opacity: 1;
  background-color: rgb(245 249 255 / var(--tw-bg-opacity, 1));
}

#acx-website .data-\[inverse\=\"true\"\]\:bg-white[data-inverse="true"] {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
}

#acx-website .data-\[stuck\=true\]\:bg-white[data-stuck="true"] {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
}

#acx-website .data-\[variant\=\"achieveHighlightBlue\"\]\:bg-\[\#eff5ff\][data-variant="achieveHighlightBlue"] {
  --tw-bg-opacity: 1;
  background-color: rgb(239 245 255 / var(--tw-bg-opacity, 1));
}

#acx-website .data-\[variant\=\"bgPrimaryHighlightBlue\"\]\:bg-\[\#eff5ff\][data-variant="bgPrimaryHighlightBlue"] {
  --tw-bg-opacity: 1;
  background-color: rgb(239 245 255 / var(--tw-bg-opacity, 1));
}

#acx-website .data-\[variant\=\"bgPrimaryHighlightBlue2\"\]\:bg-\[\#f5f9ff\][data-variant="bgPrimaryHighlightBlue2"] {
  --tw-bg-opacity: 1;
  background-color: rgb(245 249 255 / var(--tw-bg-opacity, 1));
}

#acx-website .data-\[variant\=\"blue\"\]\:bg-\[\#3300ff\][data-variant="blue"] {
  --tw-bg-opacity: 1;
  background-color: rgb(51 0 255 / var(--tw-bg-opacity, 1));
}

#acx-website .data-\[variant\=\"blue100\"\]\:bg-\[\#d2e2fe\][data-variant="blue100"] {
  --tw-bg-opacity: 1;
  background-color: rgb(210 226 254 / var(--tw-bg-opacity, 1));
}

#acx-website .data-\[variant\=\"blue25\"\]\:bg-\[\#f5f9ff\][data-variant="blue25"] {
  --tw-bg-opacity: 1;
  background-color: rgb(245 249 255 / var(--tw-bg-opacity, 1));
}

#acx-website .data-\[variant\=\"neutralGrey8\"\]\:bg-neutral-25[data-variant="neutralGrey8"] {
  --tw-bg-opacity: 1;
  background-color: rgb(248 249 252 / var(--tw-bg-opacity, 1));
}

#acx-website .data-\[variant\=\"white\"\]\:bg-white[data-variant="white"] {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
}

#acx-website .data-\[container-color\=\"blue\"\]\:p-6[data-container-color="blue"] {
  padding: 1.5rem;
}

#acx-website .data-\[default-pad-y\=\"true\"\]\:py-12[data-default-pad-y="true"] {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

#acx-website .data-\[marker-style\=\"black1\"\]\:px-8[data-marker-style="black1"] {
  padding-left: 2rem;
  padding-right: 2rem;
}

#acx-website .data-\[marker-style\=\"black1\"\]\:py-0[data-marker-style="black1"] {
  padding-top: 0px;
  padding-bottom: 0px;
}

#acx-website .data-\[marker-style\=\"blue1\"\]\:px-4[data-marker-style="blue1"] {
  padding-left: 1rem;
  padding-right: 1rem;
}

#acx-website .data-\[marker-style\=\"blue1\"\]\:py-12[data-marker-style="blue1"] {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

#acx-website .data-\[variation\=\"variation1\"\]\:px-4[data-variation="variation1"] {
  padding-left: 1rem;
  padding-right: 1rem;
}

#acx-website .data-\[variation\=\"variation1\"\]\:py-8[data-variation="variation1"] {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

#acx-website .data-\[disabled\=\"true\"\]\:text-\[\#c0cbd8\][data-disabled="true"] {
  --tw-text-opacity: 1;
  color: rgb(192 203 216 / var(--tw-text-opacity, 1));
}

#acx-website .data-\[variant\=\"awards\"\]\:first-of-type\:mr-5:first-of-type[data-variant="awards"] {
  margin-right: 1.25rem;
}

#acx-website .hover\:data-\[testid\=\"card-title\"\]\:underline[data-testid="card-title"]:hover {
  text-decoration-line: underline;
}

#acx-website :is(.group\/item[data-active="true"] .group-data-\[active\=true\]\/item\:z-\[1\]) {
  z-index: 1;
}

#acx-website :is(.group[data-component="GraphicCardList"] .group-data-\[component\=\"GraphicCardList\"\]\:m-\[30px\]) {
  margin: 30px;
}

#acx-website :is(.group[data-marker-style="black1"] .group-data-\[marker-style\=\"black1\"\]\:mt-0) {
  margin-top: 0px;
}

#acx-website :is(.group[data-marker-style="blue1"] .group-data-\[marker-style\=\"blue1\"\]\:mt-0) {
  margin-top: 0px;
}

#acx-website :is(.group[data-variant="carousel"] .group-data-\[variant\=\"carousel\"\]\:mb-1) {
  margin-bottom: 0.25rem;
}

#acx-website :is(.group[data-variant="carousel"] .group-data-\[variant\=\"carousel\"\]\:mt-10) {
  margin-top: 2.5rem;
}

#acx-website :is(.group[data-variation="variation1"] .group-data-\[variation\=\"variation1\"\]\:mt-\[-35px\]) {
  margin-top: -35px;
}

#acx-website :is(.group[data-hero-variant="debt-consolidation"] .group-data-\[hero-variant\=\"debt-consolidation\"\]\:min-h-\[30vh\]) {
  min-height: 30vh;
}

#acx-website :is(.group[data-component="GraphicCardList"] .group-data-\[component\=\"GraphicCardList\"\]\:min-w-\[250px\]) {
  min-width: 250px;
}

#acx-website :is(.group[data-component="GraphicCommentBubble"] .group-data-\[component\=\"GraphicCommentBubble\"\]\:min-w-\[250px\]) {
  min-width: 250px;
}

#acx-website :is(.group[data-variant="carousel"] .group-data-\[variant\=\"carousel\"\]\:min-w-\[280px\]) {
  min-width: 280px;
}

#acx-website :is(.group[data-hero-variant="debt-consolidation"] .group-data-\[hero-variant\=\"debt-consolidation\"\]\:max-w-\[375px\]) {
  max-width: 375px;
}

#acx-website :is(.group[data-hero-variant="home"] .group-data-\[hero-variant\=\"home\"\]\:max-w-\[447px\]) {
  max-width: 447px;
}

#acx-website :is(.group[data-narrow] .group-data-\[narrow\]\:max-w-full) {
  max-width: 100%;
}

#acx-website :is(.group[data-variant="carousel"] .group-data-\[variant\=\"carousel\"\]\:max-w-\[280px\]) {
  max-width: 280px;
}

#acx-website :is(.group\/item[data-active="true"] .group-data-\[active\=true\]\/item\:rounded-3xl) {
  border-radius: 1.5rem;
}

#acx-website :is(.group[data-marker-style="blue1"] .group-data-\[marker-style\=\"blue1\"\]\:border-x-0) {
  border-left-width: 0px;
  border-right-width: 0px;
}

#acx-website :is(.group[data-marker-style="blue1"] .group-data-\[marker-style\=\"blue1\"\]\:border-b) {
  border-bottom-width: 1px;
}

#acx-website :is(.group[data-marker-style="blue1"] .group-data-\[marker-style\=\"blue1\"\]\:border-t-0) {
  border-top-width: 0px;
}

#acx-website :is(.group[data-marker-style="blue1"] .group-data-\[marker-style\=\"blue1\"\]\:border-solid) {
  border-style: solid;
}

#acx-website :is(.group[data-marker-style="blue1"] .group-data-\[marker-style\=\"blue1\"\]\:border-\[\#94bbfe\]) {
  --tw-border-opacity: 1;
  border-color: rgb(148 187 254 / var(--tw-border-opacity, 1));
}

#acx-website :is(.group\/item[data-active="true"] .group-data-\[active\=true\]\/item\:bg-\[\#3300ff\]) {
  --tw-bg-opacity: 1;
  background-color: rgb(51 0 255 / var(--tw-bg-opacity, 1));
}

#acx-website :is(.group\/item[data-active="true"] .group-data-\[active\=true\]\/item\:bg-white) {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
}

#acx-website :is(.group[data-narrow] .group-data-\[narrow\]\:p-0) {
  padding: 0px;
}

#acx-website :is(.group[data-no-margin="false"] .group-data-\[no-margin\=\"false\"\]\:p-0) {
  padding: 0px;
}

#acx-website :is(.group[data-hero-variant="debt-consolidation"] .group-data-\[hero-variant\=\"debt-consolidation\"\]\:px-0) {
  padding-left: 0px;
  padding-right: 0px;
}

#acx-website :is(.group[data-variation="variation1"] .group-data-\[variation\=\"variation1\"\]\:px-0) {
  padding-left: 0px;
  padding-right: 0px;
}

#acx-website :is(.group[data-variation="variation1"] .group-data-\[variation\=\"variation1\"\]\:px-\[10px\]) {
  padding-left: 10px;
  padding-right: 10px;
}

#acx-website :is(.group[data-variation="variation1"] .group-data-\[variation\=\"variation1\"\]\:py-0) {
  padding-top: 0px;
  padding-bottom: 0px;
}

#acx-website :is(.group[data-variation="variation1"] .group-data-\[variation\=\"variation1\"\]\:py-4) {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

#acx-website :is(.group[data-hero-variant="debt-consolidation"] .group-data-\[hero-variant\=\"debt-consolidation\"\]\:pt-\[246px\]) {
  padding-top: 246px;
}

#acx-website :is(.group[data-marker-style="blue1"] .group-data-\[marker-style\=\"blue1\"\]\:pb-8) {
  padding-bottom: 2rem;
}

#acx-website :is(.group\/item[data-active="true"] .group-data-\[active\=true\]\/item\:font-bold) {
  font-weight: 700;
}

#acx-website :is(.group\/item[data-active="true"] .group-data-\[active\=true\]\/item\:text-\[\#3300ff\]) {
  --tw-text-opacity: 1;
  color: rgb(51 0 255 / var(--tw-text-opacity, 1));
}

#acx-website :is(.group[data-marker-style="black1"] .group-data-\[marker-style\=\"black1\"\]\:text-black) {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity, 1));
}

#acx-website :is(.group[data-marker-style="blue1"] .group-data-\[marker-style\=\"blue1\"\]\:text-\[\#fff\]) {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
}

#acx-website :is(.group[data-marker-style="blue1"] .group-data-\[marker-style\=\"blue1\"\]\:text-white) {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity, 1));
}

#acx-website :is(.group[data-hero-variant="debt-consolidation"] .group-data-\[hero-variant\=\"debt-consolidation\"\]\:\[transform\:inherit\]) {
  transform: inherit;
}

#acx-website :is(.group[data-hero-variant="debt-consolidation"] .group-data-\[hero-variant\=\"debt-consolidation\"\]\:data-\[container-color\=\"blue\"\]\:p-8[data-container-color="blue"]) {
  padding: 2rem;
}

#acx-website :is(.group[data-hero-variant="debt-consolidation"] .group-data-\[hero-variant\=\"debt-consolidation\"\]\:data-\[container-color\=\"blue\"\]\:\[background\:linear-gradient\(172\.38deg\2c _\#30f_30\.04\%\2c _\#71b4ff_144\.48\%\2c _\#ddf3ff_169\.51\%\)\][data-container-color="blue"]) {
  background: linear-gradient(172.38deg, #30f 30.04%, #71b4ff 144.48%, #ddf3ff 169.51%);
}

@media not all and (min-width: 768px) {
  #acx-website :is(.group[data-hero-variant="debt-consolidation"] .group-data-\[hero-variant\=\"debt-consolidation\"\]\:max-md\:max-w-\[340px\]) {
    max-width: 340px;
  }
  #acx-website :is(.group[data-hero-variant="debt-consolidation"] .group-data-\[hero-variant\=\"debt-consolidation\"\]\:max-md\:pt-\[215px\]) {
    padding-top: 215px;
  }
}

@media (min-width: 640px) {
  #acx-website .sm\:left-\[15\.5\%\] {
    left: 15.5%;
  }
  #acx-website .sm\:left-\[5\%\] {
    left: 5%;
  }
  #acx-website .sm\:left-\[6\%\] {
    left: 6%;
  }
  #acx-website .sm\:left-\[6\.25\%\] {
    left: 6.25%;
  }
  #acx-website .sm\:top-\[44\%\] {
    top: 44%;
  }
  #acx-website .sm\:top-\[46\%\] {
    top: 46%;
  }
  #acx-website .sm\:top-\[55\%\] {
    top: 55%;
  }
  #acx-website .sm\:top-\[59\.5\%\] {
    top: 59.5%;
  }
  #acx-website .sm\:top-\[64\.5\%\] {
    top: 64.5%;
  }
  #acx-website .sm\:top-\[79\%\] {
    top: 79%;
  }
  #acx-website .sm\:col-span-4 {
    grid-column: span 4 / span 4;
  }
  #acx-website .sm\:col-start-2 {
    grid-column-start: 2;
  }
  #acx-website .sm\:block {
    display: block;
  }
  #acx-website .sm\:flex {
    display: flex;
  }
  #acx-website .sm\:h-\[216px\] {
    height: 216px;
  }
  #acx-website .sm\:h-\[315px\] {
    height: 315px;
  }
  #acx-website .sm\:w-5\/12 {
    width: 41.666667%;
  }
  #acx-website .sm\:w-7\/12 {
    width: 58.333333%;
  }
  #acx-website .sm\:w-\[222px\] {
    width: 222px;
  }
  #acx-website .sm\:w-\[500px\] {
    width: 500px;
  }
  #acx-website .sm\:w-\[66\%\] {
    width: 66%;
  }
  #acx-website .sm\:w-\[80\%\] {
    width: 80%;
  }
  #acx-website .sm\:flex-row {
    flex-direction: row;
  }
  #acx-website .sm\:items-center {
    align-items: center;
  }
  #acx-website .sm\:justify-center {
    justify-content: center;
  }
  #acx-website .sm\:pl-4 {
    padding-left: 1rem;
  }
}

@media (min-width: 768px) {
  #acx-website .md\:relative {
    position: relative;
  }
  #acx-website .md\:z-\[2\] {
    z-index: 2;
  }
  #acx-website .md\:mx-0 {
    margin-left: 0px;
    margin-right: 0px;
  }
  #acx-website .md\:my-12 {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
  #acx-website .md\:mb-0 {
    margin-bottom: 0px;
  }
  #acx-website .md\:mb-12 {
    margin-bottom: 3rem;
  }
  #acx-website .md\:mb-4 {
    margin-bottom: 1rem;
  }
  #acx-website .md\:mb-\[16px_\!important\] {
    margin-bottom: 16px !important;
  }
  #acx-website .md\:mt-0 {
    margin-top: 0px;
  }
  #acx-website .md\:mt-16 {
    margin-top: 4rem;
  }
  #acx-website .md\:\!block {
    display: block !important;
  }
  #acx-website .md\:block {
    display: block;
  }
  #acx-website .md\:flex {
    display: flex;
  }
  #acx-website .md\:\!hidden {
    display: none !important;
  }
  #acx-website .md\:hidden {
    display: none;
  }
  #acx-website .md\:h-\[100px\] {
    height: 100px;
  }
  #acx-website .md\:h-\[212px\] {
    height: 212px;
  }
  #acx-website .md\:h-\[345px\] {
    height: 345px;
  }
  #acx-website .md\:h-\[470px\] {
    height: 470px;
  }
  #acx-website .md\:min-h-\[360px\] {
    min-height: 360px;
  }
  #acx-website .md\:min-h-\[calc\(100vh-740px\)\] {
    min-height: calc(100vh - 740px);
  }
  #acx-website .md\:w-\[390px\] {
    width: 390px;
  }
  #acx-website .md\:w-\[745px\] {
    width: 745px;
  }
  #acx-website .md\:w-\[95\%\] {
    width: 95%;
  }
  #acx-website .md\:max-w-sm {
    max-width: 24rem;
  }
  #acx-website .md\:translate-x-32 {
    --tw-translate-x: 8rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }
  #acx-website .md\:translate-x-8 {
    --tw-translate-x: 2rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }
  #acx-website .md\:flex-row {
    flex-direction: row;
  }
  #acx-website .md\:items-center {
    align-items: center;
  }
  #acx-website .md\:justify-center {
    justify-content: center;
  }
  #acx-website .md\:gap-4 {
    gap: 1rem;
  }
  #acx-website .md\:gap-x-4 {
    -moz-column-gap: 1rem;
         column-gap: 1rem;
  }
  #acx-website .md\:gap-y-16 {
    row-gap: 4rem;
  }
  #acx-website .md\:rounded-\[20px\] {
    border-radius: 20px;
  }
  #acx-website .md\:rounded-none {
    border-radius: 0px;
  }
  #acx-website .md\:rounded-bl-\[20px\] {
    border-bottom-left-radius: 20px;
  }
  #acx-website .md\:rounded-br-\[10px\] {
    border-bottom-right-radius: 10px;
  }
  #acx-website .md\:rounded-tl-\[0\] {
    border-top-left-radius: 0;
  }
  #acx-website .md\:rounded-tr-\[10px\] {
    border-top-right-radius: 10px;
  }
  #acx-website .md\:border-b-0 {
    border-bottom-width: 0px;
  }
  #acx-website .md\:border-\[none\] {
    border-color: none;
  }
  #acx-website .md\:bg-transparent {
    background-color: transparent;
  }
  #acx-website .md\:bg-cover {
    background-size: cover;
  }
  #acx-website .md\:bg-\[position\:center_center\] {
    background-position: center center;
  }
  #acx-website .md\:p-0 {
    padding: 0px;
  }
  #acx-website .md\:p-2 {
    padding: 0.5rem;
  }
  #acx-website .md\:p-4 {
    padding: 1rem;
  }
  #acx-website .md\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  #acx-website .md\:py-\[50px\] {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  #acx-website .md\:py-\[64px\] {
    padding-top: 64px;
    padding-bottom: 64px;
  }
  #acx-website .md\:pb-12 {
    padding-bottom: 3rem;
  }
  #acx-website .md\:pb-4 {
    padding-bottom: 1rem;
  }
  #acx-website .md\:pl-10 {
    padding-left: 2.5rem;
  }
  #acx-website .md\:text-left {
    text-align: left;
  }
  #acx-website .md\:text-right {
    text-align: right;
  }
  #acx-website .md\:text-\[68px\] {
    font-size: 68px;
  }
  #acx-website .md\:text-sm {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
  #acx-website .md\:leading-10 {
    line-height: 2.5rem;
  }
  #acx-website .md\:\[align-items\:normal\] {
    align-items: normal;
  }
  #acx-website .md\:data-\[variant-progress\=\"true\"\]\:min-h-\[790px\][data-variant-progress="true"] {
    min-height: 790px;
  }
  #acx-website :is(.group[data-hero-variant="debt-consolidation"] .group-data-\[hero-variant\=\"debt-consolidation\"\]\:md\:min-h-\[22vh\]) {
    min-height: 22vh;
  }
  #acx-website :is(.group[data-hero-variant="debt-consolidation"] .group-data-\[hero-variant\=\"debt-consolidation\"\]\:md\:max-w-\[462px\]) {
    max-width: 462px;
  }
}

@media (min-width: 1024px) {
  #acx-website .lg\:absolute {
    position: absolute;
  }
  #acx-website .lg\:relative {
    position: relative;
  }
  #acx-website .lg\:bottom-14 {
    bottom: 3.5rem;
  }
  #acx-website .lg\:bottom-\[2\%\] {
    bottom: 2%;
  }
  #acx-website .lg\:left-0 {
    left: 0px;
  }
  #acx-website .lg\:left-\[11\%\] {
    left: 11%;
  }
  #acx-website .lg\:left-\[12\.25\%\] {
    left: 12.25%;
  }
  #acx-website .lg\:left-\[13\%\] {
    left: 13%;
  }
  #acx-website .lg\:left-\[13\.25\%\] {
    left: 13.25%;
  }
  #acx-website .lg\:left-\[59\.25\%\] {
    left: 59.25%;
  }
  #acx-website .lg\:left-\[62\.5\%\] {
    left: 62.5%;
  }
  #acx-website .lg\:right-0 {
    right: 0px;
  }
  #acx-website .lg\:right-\[-70px\] {
    right: -70px;
  }
  #acx-website .lg\:right-\[-90px\] {
    right: -90px;
  }
  #acx-website .lg\:top-0 {
    top: 0px;
  }
  #acx-website .lg\:top-\[55\%\] {
    top: 55%;
  }
  #acx-website .lg\:z-\[1\] {
    z-index: 1;
  }
  #acx-website .lg\:m-0 {
    margin: 0px;
  }
  #acx-website .lg\:m-auto {
    margin: auto;
  }
  #acx-website .lg\:mx-0 {
    margin-left: 0px;
    margin-right: 0px;
  }
  #acx-website .lg\:mb-0 {
    margin-bottom: 0px;
  }
  #acx-website .lg\:mb-16 {
    margin-bottom: 4rem;
  }
  #acx-website .lg\:mb-6 {
    margin-bottom: 1.5rem;
  }
  #acx-website .lg\:mb-8 {
    margin-bottom: 2rem;
  }
  #acx-website .lg\:ml-0 {
    margin-left: 0px;
  }
  #acx-website .lg\:ml-\[-160px\] {
    margin-left: -160px;
  }
  #acx-website .lg\:mt-0 {
    margin-top: 0px;
  }
  #acx-website .lg\:mt-10 {
    margin-top: 2.5rem;
  }
  #acx-website .lg\:mt-16 {
    margin-top: 4rem;
  }
  #acx-website .lg\:mt-8 {
    margin-top: 2rem;
  }
  #acx-website .lg\:mt-\[-183px\] {
    margin-top: -183px;
  }
  #acx-website .lg\:mt-\[-200px\] {
    margin-top: -200px;
  }
  #acx-website .lg\:mt-\[-22\.4px\] {
    margin-top: -22.4px;
  }
  #acx-website .lg\:mt-\[-224px\] {
    margin-top: -224px;
  }
  #acx-website .lg\:\!block {
    display: block !important;
  }
  #acx-website .lg\:block {
    display: block;
  }
  #acx-website .lg\:flex {
    display: flex;
  }
  #acx-website .lg\:\!hidden {
    display: none !important;
  }
  #acx-website .lg\:hidden {
    display: none;
  }
  #acx-website .lg\:h-\[219px\] {
    height: 219px;
  }
  #acx-website .lg\:h-\[52px\] {
    height: 52px;
  }
  #acx-website .lg\:h-\[535px\] {
    height: 535px;
  }
  #acx-website .lg\:min-h-\[368px\] {
    min-height: 368px;
  }
  #acx-website .lg\:min-h-\[400px\] {
    min-height: 400px;
  }
  #acx-website .lg\:w-\[1007px\] {
    width: 1007px;
  }
  #acx-website .lg\:w-\[130\%\] {
    width: 130%;
  }
  #acx-website .lg\:w-\[390px\] {
    width: 390px;
  }
  #acx-website .lg\:w-\[45\%\] {
    width: 45%;
  }
  #acx-website .lg\:w-\[485px\] {
    width: 485px;
  }
  #acx-website .lg\:w-auto {
    width: auto;
  }
  #acx-website .lg\:w-fit {
    width: -moz-fit-content;
    width: fit-content;
  }
  #acx-website .lg\:w-full {
    width: 100%;
  }
  #acx-website .lg\:max-w-\[1007px\] {
    max-width: 1007px;
  }
  #acx-website .lg\:max-w-\[1216px\] {
    max-width: 1216px;
  }
  #acx-website .lg\:max-w-\[305px\] {
    max-width: 305px;
  }
  #acx-website .lg\:max-w-\[315px\] {
    max-width: 315px;
  }
  #acx-website .lg\:max-w-\[320px\] {
    max-width: 320px;
  }
  #acx-website .lg\:max-w-\[485px\] {
    max-width: 485px;
  }
  #acx-website .lg\:max-w-\[initial\] {
    max-width: initial;
  }
  #acx-website .lg\:max-w-max {
    max-width: -moz-max-content;
    max-width: max-content;
  }
  #acx-website .lg\:max-w-none {
    max-width: none;
  }
  #acx-website .lg\:max-w-xl {
    max-width: 36rem;
  }
  #acx-website .lg\:translate-x-0 {
    --tw-translate-x: 0px;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }
  #acx-website .lg\:translate-x-16 {
    --tw-translate-x: 4rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }
  #acx-website .lg\:transform-none {
    transform: none;
  }
  #acx-website .lg\:flex-row {
    flex-direction: row;
  }
  #acx-website .lg\:flex-row-reverse {
    flex-direction: row-reverse;
  }
  #acx-website .lg\:content-center {
    align-content: center;
  }
  #acx-website .lg\:items-end {
    align-items: flex-end;
  }
  #acx-website .lg\:items-center {
    align-items: center;
  }
  #acx-website .lg\:justify-start {
    justify-content: flex-start;
  }
  #acx-website .lg\:justify-center {
    justify-content: center;
  }
  #acx-website .lg\:justify-between {
    justify-content: space-between;
  }
  #acx-website .lg\:justify-around {
    justify-content: space-around;
  }
  #acx-website .lg\:self-start {
    align-self: flex-start;
  }
  #acx-website .lg\:overflow-hidden {
    overflow: hidden;
  }
  #acx-website .lg\:rounded-\[0_0_10px_10px\] {
    border-radius: 0 0 10px 10px;
  }
  #acx-website .lg\:rounded-\[10px_10px_0px_0px\] {
    border-radius: 10px 10px 0px 0px;
  }
  #acx-website .lg\:border {
    border-width: 1px;
  }
  #acx-website .lg\:border-y-0 {
    border-top-width: 0px;
    border-bottom-width: 0px;
  }
  #acx-website .lg\:border-b {
    border-bottom-width: 1px;
  }
  #acx-website .lg\:border-b-0 {
    border-bottom-width: 0px;
  }
  #acx-website .lg\:border-l {
    border-left-width: 1px;
  }
  #acx-website .lg\:border-l-0 {
    border-left-width: 0px;
  }
  #acx-website .lg\:border-r {
    border-right-width: 1px;
  }
  #acx-website .lg\:border-solid {
    border-style: solid;
  }
  #acx-website .lg\:border-\[\#42546b\] {
    --tw-border-opacity: 1;
    border-color: rgb(66 84 107 / var(--tw-border-opacity, 1));
  }
  #acx-website .lg\:border-\[\#c0cbd8\] {
    --tw-border-opacity: 1;
    border-color: rgb(192 203 216 / var(--tw-border-opacity, 1));
  }
  #acx-website .lg\:border-neutral-300 {
    --tw-border-opacity: 1;
    border-color: rgb(92 112 138 / var(--tw-border-opacity, 1));
  }
  #acx-website .lg\:border-x-\[\#c0cbd8\] {
    --tw-border-opacity: 1;
    border-left-color: rgb(192 203 216 / var(--tw-border-opacity, 1));
    border-right-color: rgb(192 203 216 / var(--tw-border-opacity, 1));
  }
  #acx-website .lg\:border-b-\[\#c0cbd8\] {
    --tw-border-opacity: 1;
    border-bottom-color: rgb(192 203 216 / var(--tw-border-opacity, 1));
  }
  #acx-website .lg\:bg-white {
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity, 1));
  }
  #acx-website .lg\:p-0 {
    padding: 0px;
  }
  #acx-website .lg\:p-4 {
    padding: 1rem;
  }
  #acx-website .lg\:p-8 {
    padding: 2rem;
  }
  #acx-website .lg\:px-0 {
    padding-left: 0px;
    padding-right: 0px;
  }
  #acx-website .lg\:px-16 {
    padding-left: 4rem;
    padding-right: 4rem;
  }
  #acx-website .lg\:px-3 {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
  #acx-website .lg\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  #acx-website .lg\:px-\[300px\] {
    padding-left: 300px;
    padding-right: 300px;
  }
  #acx-website .lg\:py-0 {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  #acx-website .lg\:py-12 {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
  #acx-website .lg\:py-16 {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  #acx-website .lg\:py-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  #acx-website .lg\:py-8 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  #acx-website .lg\:pb-0 {
    padding-bottom: 0px;
  }
  #acx-website .lg\:pb-4 {
    padding-bottom: 1rem;
  }
  #acx-website .lg\:pb-7 {
    padding-bottom: 1.75rem;
  }
  #acx-website .lg\:pl-4 {
    padding-left: 1rem;
  }
  #acx-website .lg\:pr-0 {
    padding-right: 0px;
  }
  #acx-website .lg\:pr-4 {
    padding-right: 1rem;
  }
  #acx-website .lg\:pt-0 {
    padding-top: 0px;
  }
  #acx-website .lg\:pt-16 {
    padding-top: 4rem;
  }
  #acx-website .lg\:pt-4 {
    padding-top: 1rem;
  }
  #acx-website .lg\:pt-\[48px\] {
    padding-top: 48px;
  }
  #acx-website .lg\:text-left {
    text-align: left;
  }
  #acx-website .lg\:text-base {
    font-size: 1rem;
    line-height: 1.5rem;
  }
  #acx-website .lg\:text-sm {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
  #acx-website .lg\:\[background\:none\] {
    background: none;
  }
  #acx-website .lg\:\[justify-content\:inherit\] {
    justify-content: inherit;
  }
  #acx-website .lg\:last\:border-r-0:last-child {
    border-right-width: 0px;
  }
  #acx-website .data-\[default-pad-y\=\"true\"\]\:lg\:py-16[data-default-pad-y="true"] {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  #acx-website .lg\:data-\[marker-style\=\"black1\"\]\:px-0[data-marker-style="black1"] {
    padding-left: 0px;
    padding-right: 0px;
  }
  #acx-website .lg\:data-\[marker-style\=\"blue1\"\]\:px-4[data-marker-style="blue1"] {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  #acx-website .lg\:data-\[marker-style\=\"blue1\"\]\:py-16[data-marker-style="blue1"] {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  #acx-website .lg\:data-\[marker-style\=\"black1\"\]\:pb-16[data-marker-style="black1"] {
    padding-bottom: 4rem;
  }
  #acx-website .lg\:data-\[marker-style\=\"black1\"\]\:pt-8[data-marker-style="black1"] {
    padding-top: 2rem;
  }
  #acx-website .lg\:data-\[marker-style\=\"blue2\"\]\:pb-16[data-marker-style="blue2"] {
    padding-bottom: 4rem;
  }
  #acx-website .lg\:data-\[variant\=\"facts\"\]\:pt-0[data-variant="facts"] {
    padding-top: 0px;
  }
  #acx-website :is(.group[data-variation="variation1"] .lg\:group-data-\[variation\=\"variation1\"\]\:right-\[-165px\]) {
    right: -165px;
  }
  #acx-website :is(.group[data-component="GraphicCardList"] .group-data-\[component\=\"GraphicCardList\"\]\:lg\:m-\[55px\]) {
    margin: 55px;
  }
  #acx-website :is(.group[data-marker-style="blue1"] .group-data-\[marker-style\=\"blue1\"\]\:lg\:mt-0) {
    margin-top: 0px;
  }
  #acx-website :is(.group[data-variation="variation1"] .lg\:group-data-\[variation\=\"variation1\"\]\:mt-\[-72px\]) {
    margin-top: -72px;
  }
  #acx-website :is(.group[data-hero-variant="debt-consolidation"] .group-data-\[hero-variant\=\"debt-consolidation\"\]\:lg\:min-h-\[363px\]) {
    min-height: 363px;
  }
  #acx-website :is(.group[data-component="GraphicCardList"] .lg\:group-data-\[component\=\"GraphicCardList\"\]\:w-\[350px\]) {
    width: 350px;
  }
  #acx-website :is(.group[data-variation="variation1"] .lg\:group-data-\[variation\=\"variation1\"\]\:w-full) {
    width: 100%;
  }
  #acx-website :is(.group[data-component="GraphicCardList"] .lg\:group-data-\[component\=\"GraphicCardList\"\]\:min-w-\[350px\]) {
    min-width: 350px;
  }
  #acx-website :is(.group[data-hero-variant="debt-consolidation"] .group-data-\[hero-variant\=\"debt-consolidation\"\]\:lg\:max-w-\[340px\]) {
    max-width: 340px;
  }
  #acx-website :is(.group[data-hero-variant="home"] .group-data-\[hero-variant\=\"home\"\]\:lg\:max-w-\[506px\]) {
    max-width: 506px;
  }
  #acx-website :is(.group[data-marker-style="black1"] .group-data-\[marker-style\=\"black1\"\]\:lg\:border-y-0) {
    border-top-width: 0px;
    border-bottom-width: 0px;
  }
  #acx-website :is(.group[data-marker-style="blue1"] .group-data-\[marker-style\=\"blue1\"\]\:lg\:border-y-0) {
    border-top-width: 0px;
    border-bottom-width: 0px;
  }
  #acx-website :is(.group[data-marker-style="black1"] .group-data-\[marker-style\=\"black1\"\]\:lg\:border-r) {
    border-right-width: 1px;
  }
  #acx-website :is(.group[data-marker-style="blue1"] .group-data-\[marker-style\=\"blue1\"\]\:lg\:border-b-0) {
    border-bottom-width: 0px;
  }
  #acx-website :is(.group[data-marker-style="blue1"] .group-data-\[marker-style\=\"blue1\"\]\:lg\:border-l-0) {
    border-left-width: 0px;
  }
  #acx-website :is(.group[data-marker-style="blue1"] .group-data-\[marker-style\=\"blue1\"\]\:lg\:border-r) {
    border-right-width: 1px;
  }
  #acx-website :is(.group[data-marker-style="blue1"] .group-data-\[marker-style\=\"blue1\"\]\:lg\:border-solid) {
    border-style: solid;
  }
  #acx-website :is(.group[data-marker-style="black1"] .group-data-\[marker-style\=\"black1\"\]\:lg\:border-\[\#c0cbd8\]) {
    --tw-border-opacity: 1;
    border-color: rgb(192 203 216 / var(--tw-border-opacity, 1));
  }
  #acx-website :is(.group[data-marker-style="blue1"] .group-data-\[marker-style\=\"blue1\"\]\:lg\:border-\[\#94bbfe\]) {
    --tw-border-opacity: 1;
    border-color: rgb(148 187 254 / var(--tw-border-opacity, 1));
  }
  #acx-website :is(.group[data-hero-variant="debt-consolidation"] .group-data-\[hero-variant\=\"debt-consolidation\"\]\:lg\:p-0) {
    padding: 0px;
  }
  #acx-website :is(.group[data-hero-variant="debt-consolidation"] .group-data-\[hero-variant\=\"debt-consolidation\"\]\:lg\:px-0) {
    padding-left: 0px;
    padding-right: 0px;
  }
  #acx-website :is(.group[data-hero-variant="debt-consolidation"] .group-data-\[hero-variant\=\"debt-consolidation\"\]\:lg\:py-\[48px\]) {
    padding-top: 48px;
    padding-bottom: 48px;
  }
  #acx-website :is(.group[data-hero-variant="debt-consolidation"] .group-data-\[hero-variant\=\"debt-consolidation\"\]\:lg\:pb-\[8px_\!important\]) {
    padding-bottom: 8px !important;
  }
  #acx-website :is(.group[data-arrow="true"] .lg\:group-data-\[arrow\=\"true\"\]\:first\:pl-\[104px\]:first-child) {
    padding-left: 104px;
  }
  #acx-website :is(.group[data-marker-style="black1"] .group-data-\[marker-style\=\"black1\"\]\:lg\:last\:border-r-0:last-child) {
    border-right-width: 0px;
  }
  #acx-website :is(.group[data-marker-style="blue1"] .group-data-\[marker-style\=\"blue1\"\]\:lg\:last\:border-r-0:last-child) {
    border-right-width: 0px;
  }
  #acx-website :is(.group[data-arrow="true"] .lg\:group-data-\[arrow\=\"true\"\]\:last\:pl-\[104px\]:last-child) {
    padding-left: 104px;
  }
  #acx-website :is(.group[data-hero-variant="debt-consolidation"] .lg\:group-data-\[hero-variant\=\"debt-consolidation\"\]\:data-\[container-color\=\"blue\"\]\:px-\[40px\][data-container-color="blue"]) {
    padding-left: 40px;
    padding-right: 40px;
  }
}

@media (min-width: 1280px) {
  #acx-website .xl\:left-\[12\.25\%\] {
    left: 12.25%;
  }
  #acx-website .xl\:left-\[13\%\] {
    left: 13%;
  }
  #acx-website .xl\:left-\[20\.5\%\] {
    left: 20.5%;
  }
  #acx-website .xl\:right-\[-113px\] {
    right: -113px;
  }
  #acx-website .xl\:col-span-4 {
    grid-column: span 4 / span 4;
  }
  #acx-website .xl\:col-start-5 {
    grid-column-start: 5;
  }
  #acx-website .xl\:m-0\.5 {
    margin: 0.125rem;
  }
  #acx-website .xl\:m-auto {
    margin: auto;
  }
  #acx-website .xl\:mb-12 {
    margin-bottom: 3rem;
  }
  #acx-website .xl\:mt-\[8px_\!important\] {
    margin-top: 8px !important;
  }
  #acx-website .xl\:flex {
    display: flex;
  }
  #acx-website .xl\:h-\[339px\] {
    height: 339px;
  }
  #acx-website .xl\:max-h-\[stretch\] {
    max-height: -webkit-fill-available;
    max-height: -moz-available;
    max-height: stretch;
  }
  #acx-website .xl\:min-h-\[380px\] {
    min-height: 380px;
  }
  #acx-website .xl\:min-h-\[723px\] {
    min-height: 723px;
  }
  #acx-website .xl\:w-full {
    width: 100%;
  }
  #acx-website .xl\:min-w-\[380px\] {
    min-width: 380px;
  }
  #acx-website .xl\:min-w-\[520px\] {
    min-width: 520px;
  }
  #acx-website .xl\:max-w-\[450px\] {
    max-width: 450px;
  }
  #acx-website .xl\:max-w-\[920px\] {
    max-width: 920px;
  }
  #acx-website .xl\:translate-x-16 {
    --tw-translate-x: 4rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }
  #acx-website .xl\:grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }
  #acx-website .xl\:flex-row {
    flex-direction: row;
  }
  #acx-website .xl\:p-0 {
    padding: 0px;
  }
  #acx-website .xl\:px-0 {
    padding-left: 0px;
    padding-right: 0px;
  }
  #acx-website .xl\:py-0 {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  #acx-website .xl\:py-\[80\] {
    padding-top: 80;
    padding-bottom: 80;
  }
  #acx-website .xl\:pb-8 {
    padding-bottom: 2rem;
  }
  #acx-website .xl\:pl-10 {
    padding-left: 2.5rem;
  }
  #acx-website .xl\:pr-0 {
    padding-right: 0px;
  }
  #acx-website .xl\:pt-0 {
    padding-top: 0px;
  }
  #acx-website .xl\:text-left {
    text-align: left;
  }
  #acx-website .xl\:\[align-items\:normal\] {
    align-items: normal;
  }
  #acx-website .xl\:\[transform\:none\] {
    transform: none;
  }
  #acx-website .xl\:\[transform\:translateX\(calc\(\(100\%-\(1280px-\(2\*32px\+64px\)\)\)\/2\)\)\] {
    transform: translateX(calc((100% - (1280px - (2 * 32px + 64px))) / 2));
  }
  #acx-website .data-\[container-color\=\"blue\"\]\:xl\:p-\[40px\][data-container-color="blue"] {
    padding: 40px;
  }
  #acx-website .data-\[container-color\=\"blue\"\]\:xl\:px-0[data-container-color="blue"] {
    padding-left: 0px;
    padding-right: 0px;
  }
  #acx-website .data-\[container-color\=\"blue\"\]\:xl\:py-8[data-container-color="blue"] {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  #acx-website .xl\:data-\[arrow\=\"true\"\]\:\[transform\:none\][data-arrow="true"] {
    transform: none;
  }
  #acx-website :is(.group[data-hero-variant="debt-consolidation"] .group-data-\[hero-variant\=\"debt-consolidation\"\]\:xl\:min-h-\[inherit\]) {
    min-height: inherit;
  }
  #acx-website :is(.group[data-hero-variant="debt-consolidation"] .group-data-\[hero-variant\=\"debt-consolidation\"\]\:xl\:max-w-\[462px\]) {
    max-width: 462px;
  }
  #acx-website :is(.group[data-hero-variant="debt-consolidation"] .group-data-\[hero-variant\=\"debt-consolidation\"\]\:xl\:gap-\[48px\]) {
    gap: 48px;
  }
  #acx-website :is(.group[data-hero-variant="debt-consolidation"] .group-data-\[hero-variant\=\"debt-consolidation\"\]\:xl\:p-0) {
    padding: 0px;
  }
  #acx-website :is(.group[data-hero-variant="debt-consolidation"] .group-data-\[hero-variant\=\"debt-consolidation\"\]\:xl\:py-\[48px\]) {
    padding-top: 48px;
    padding-bottom: 48px;
  }
}

@media (min-width: 1440px) {
  #acx-website .\32xl\:m-0 {
    margin: 0px;
  }
  #acx-website .\32xl\:h-\[initial\] {
    height: initial;
  }
  #acx-website .\32xl\:w-\[585px\] {
    width: 585px;
  }
  #acx-website .\32xl\:w-full {
    width: 100%;
  }
  #acx-website .\32xl\:max-w-\[585px\] {
    max-width: 585px;
  }
  #acx-website .\32xl\:max-w-full {
    max-width: 100%;
  }
  #acx-website .\32xl\:pr-\[146px\] {
    padding-right: 146px;
  }
}

#acx-website :is(.\[\&\>\*\+\*\]\:mt-4>*+*) {
  margin-top: 1rem;
}

#acx-website :is(.\[\&\>\*\+\*\]\:block>*+*) {
  display: block;
}

#acx-website :is(.group[data-hero-variant="debt-consolidation"] .group-data-\[hero-variant\=\"debt-consolidation\"\]\:\[\&\>\*\+\*\]\:mt-4>*+*) {
  margin-top: 1rem;
}

#acx-website :is(.group[data-hero-variant="debt-consolidation"] .group-data-\[hero-variant\=\"debt-consolidation\"\]\:\[\&\>\*\+\*\]\:block>*+*) {
  display: block;
}

#acx-website :is(.group[data-item-id] .\[\&\>\*\:nth-child\(3n\+1\)\]\:group-data-\[item-id\]\:border-r>*:nth-child(3n+1)) {
  border-right-width: 1px;
}

#acx-website :is(.group[data-item-id] .\[\&\>\*\:nth-child\(3n\+1\)\]\:group-data-\[item-id\]\:border-r-0>*:nth-child(3n+1)) {
  border-right-width: 0px;
}

#acx-website :is(.group[data-item-id] .\[\&\>\*\:nth-child\(3n\+1\)\]\:group-data-\[item-id\]\:border-r-neutral-150>*:nth-child(3n+1)) {
  --tw-border-opacity: 1;
  border-right-color: rgb(192 203 216 / var(--tw-border-opacity, 1));
}

#acx-website :is(.group[data-item-id] .data-\[variant\=\"facts\"\]\:\[\&\>\*\:nth-child\(3n\+1\)\]\:group-data-\[item-id\]\:border-r-0>*:nth-child(3n+1)[data-variant="facts"]) {
  border-right-width: 0px;
}

@media (min-width: 1024px) {
  #acx-website :is(.group[data-item-id] .lg\:data-\[variant\=\"facts\"\]\:\[\&\>\*\:nth-child\(3n\+1\)\]\:group-data-\[item-id\]\:border-r>*:nth-child(3n+1)[data-variant="facts"]) {
    border-right-width: 1px;
  }
  #acx-website :is(.group[data-item-id] .lg\:data-\[variant\=\"facts\"\]\:\[\&\>\*\:nth-child\(3n\+1\)\]\:group-data-\[item-id\]\:border-r-neutral-150>*:nth-child(3n+1)[data-variant="facts"]) {
    --tw-border-opacity: 1;
    border-right-color: rgb(192 203 216 / var(--tw-border-opacity, 1));
  }
}

#acx-website :is(.group[data-item-id] .\[\&\>\*\:nth-child\(3n\+2\)\]\:group-data-\[item-id\]\:border-r>*:nth-child(3n+2)) {
  border-right-width: 1px;
}

#acx-website :is(.group[data-item-id] .\[\&\>\*\:nth-child\(3n\+2\)\]\:group-data-\[item-id\]\:border-r-0>*:nth-child(3n+2)) {
  border-right-width: 0px;
}

#acx-website :is(.group[data-item-id] .\[\&\>\*\:nth-child\(3n\+2\)\]\:group-data-\[item-id\]\:border-r-neutral-150>*:nth-child(3n+2)) {
  --tw-border-opacity: 1;
  border-right-color: rgb(192 203 216 / var(--tw-border-opacity, 1));
}

#acx-website :is(.group[data-item-id] .data-\[variant\=\"facts\"\]\:\[\&\>\*\:nth-child\(3n\+2\)\]\:group-data-\[item-id\]\:border-r-0>*:nth-child(3n+2)[data-variant="facts"]) {
  border-right-width: 0px;
}

@media (min-width: 1024px) {
  #acx-website :is(.group[data-item-id] .lg\:data-\[variant\=\"facts\"\]\:\[\&\>\*\:nth-child\(3n\+2\)\]\:group-data-\[item-id\]\:border-r>*:nth-child(3n+2)[data-variant="facts"]) {
    border-right-width: 1px;
  }
  #acx-website :is(.group[data-item-id] .lg\:data-\[variant\=\"facts\"\]\:\[\&\>\*\:nth-child\(3n\+2\)\]\:group-data-\[item-id\]\:border-r-neutral-150>*:nth-child(3n+2)[data-variant="facts"]) {
    --tw-border-opacity: 1;
    border-right-color: rgb(192 203 216 / var(--tw-border-opacity, 1));
  }
  #acx-website :is(.\[\&\>\.pd-item-brand\]\:lg\:pr-\[50px\]>.pd-item-brand) {
    padding-right: 50px;
  }
}

@media (min-width: 1280px) {
  #acx-website :is(.\[\&\>\.pd-item-brand\]\:xl\:px-0>.pd-item-brand) {
    padding-left: 0px;
    padding-right: 0px;
  }
}

#acx-website :is(.\[\&\>div\]\:items-center>div) {
  align-items: center;
}

#acx-website :is(.\[\&\>div\]\:items-baseline>div) {
  align-items: baseline;
}

#acx-website :is(.\[\&\>div\]\:overflow-hidden>div) {
  overflow: hidden;
}

#acx-website :is(.\[\&\>img\]\:mb-2>img) {
  margin-bottom: 0.5rem;
}

#acx-website :is(.\[\&\>img\]\:object-contain>img) {
  -o-object-fit: contain;
     object-fit: contain;
}

#acx-website :is(.\[\&\>li\]\:before\:table>li)::before {
  content: var(--tw-content);
  display: table;
}

#acx-website :is(.\[\&\>li\]\:before\:content-\[\"_\"\]>li)::before {
  --tw-content: " ";
  content: var(--tw-content);
}

#acx-website :is(.\[\&\>li\]\:after\:clear-both>li)::after {
  content: var(--tw-content);
  clear: both;
}

#acx-website :is(.\[\&\>li\]\:after\:table>li)::after {
  content: var(--tw-content);
  display: table;
}

#acx-website :is(.\[\&\>li\]\:after\:content-\[\"_\"\]>li)::after {
  --tw-content: " ";
  content: var(--tw-content);
}

#acx-website :is(.\[\&\>p\>span\>a\:hover\]\:text-blue-500>p>span>a:hover) {
  --tw-text-opacity: 1;
  color: rgb(51 0 255 / var(--tw-text-opacity, 1));
}

#acx-website :is(.\[\&\>p\>span\>a\]\:text-black>p>span>a) {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity, 1));
}

#acx-website :is(.\[\&\>div\]\:\[\&\>th\]\:w-full>th>div) {
  width: 100%;
}

#acx-website :is(.\[\&\>div\]\:\[\&\>tr\]\:w-full>tr>div) {
  width: 100%;
}

#acx-website :is(.\[\&\>ul\]\:m-0>ul) {
  margin: 0px;
}

@media not all and (min-width: 1024px) {
  #acx-website .\[\&\[data-hero-variant\=\"debt-consolidation\"\]\]\:max-lg\:h-max[data-hero-variant="debt-consolidation"] {
    height: -moz-max-content;
    height: max-content;
  }
}

@media not all and (min-width: 768px) {
  #acx-website .\[\&\[data-hero-variant\=\"debt-consolidation\"\]\]\:max-md\:h-max[data-hero-variant="debt-consolidation"] {
    height: -moz-max-content;
    height: max-content;
  }
}

#acx-website .\[\&\]\:bg-\[\#56a8f6\] {
  --tw-bg-opacity: 1;
  background-color: rgb(86 168 246 / var(--tw-bg-opacity, 1));
}

#acx-website :is(.\[\&_\.MuiSlider-rail\]\:h-2\.5 .MuiSlider-rail) {
  height: 0.625rem;
}

#acx-website :is(.\[\&_\.MuiSlider-rail\]\:border-0 .MuiSlider-rail) {
  border-width: 0px;
}

#acx-website :is(.\[\&_\.MuiSlider-rail\]\:opacity-100 .MuiSlider-rail) {
  opacity: 1;
}

#acx-website :is(.\[\&_\.MuiSlider-thumb\]\:h-5 .MuiSlider-thumb) {
  height: 1.25rem;
}

#acx-website :is(.\[\&_\.MuiSlider-thumb\]\:border-\[2px\] .MuiSlider-thumb) {
  border-width: 2px;
}

#acx-website :is(.\[\&_\.MuiSlider-thumb\]\:border-solid .MuiSlider-thumb) {
  border-style: solid;
}

#acx-website :is(.\[\&_\.MuiSlider-thumb\]\:border-\[\#f5f9ff\] .MuiSlider-thumb) {
  --tw-border-opacity: 1;
  border-color: rgb(245 249 255 / var(--tw-border-opacity, 1));
}

#acx-website :is(.\[\&_\.MuiSlider-thumb\]\:bg-\[\#94bbfe\] .MuiSlider-thumb) {
  --tw-bg-opacity: 1;
  background-color: rgb(148 187 254 / var(--tw-bg-opacity, 1));
}

#acx-website :is(.\[\&_\.MuiSlider-track\]\:h-2\.5 .MuiSlider-track) {
  height: 0.625rem;
}

#acx-website :is(.\[\&_\.MuiSlider-track\]\:border-0 .MuiSlider-track) {
  border-width: 0px;
}

#acx-website :is(.\[\&_\.MuiSlider-track\]\:bg-\[\#94bbfe\] .MuiSlider-track) {
  --tw-bg-opacity: 1;
  background-color: rgb(148 187 254 / var(--tw-bg-opacity, 1));
}

#acx-website :is(.\[\&_\.MuiSlider-track\]\:text-\[\#94bbfe\] .MuiSlider-track) {
  --tw-text-opacity: 1;
  color: rgb(148 187 254 / var(--tw-text-opacity, 1));
}

#acx-website :is(.\[\&_\.button\]\:z-\[1\] .button) {
  z-index: 1;
}

#acx-website :is(.\[\&_\.button\]\:mx-\[2\.4px\] .button) {
  margin-left: 2.4px;
  margin-right: 2.4px;
}

#acx-website :is(.\[\&_\.button\]\:my-0 .button) {
  margin-top: 0px;
  margin-bottom: 0px;
}

#acx-website :is(.\[\&_\.button\]\:px-6 .button) {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

#acx-website :is(.\[\&_\.button\]\:py-3 .button) {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

@media (min-width: 1280px) {
  #acx-website :is(.xl\:\[\&_\.button\]\:px-5 .button) {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
  #acx-website :is(.xl\:\[\&_\.button\]\:py-2 .button) {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
}

#acx-website :is(.\[\&_\.pd-item-card-image\]\:left-\[152px\] .pd-item-card-image) {
  left: 152px;
}

#acx-website :is(.\[\&_\.pd-item-card-image\]\:right-\[-165px\] .pd-item-card-image) {
  right: -165px;
}

#acx-website :is(.\[\&_\.pd-item-card-image\]\:mt-\[-72px\] .pd-item-card-image) {
  margin-top: -72px;
}

#acx-website :is(.\[\&_\.pd-item-card-image\]\:w-full .pd-item-card-image) {
  width: 100%;
}

#acx-website :is(.group[data-variation="variation1"] .group-data-\[variation\=\"variation1\"\]\:\[\&_\.pd-item-card-image\]\:right-\[-165px\] .pd-item-card-image) {
  right: -165px;
}

#acx-website :is(.group[data-variation="variation1"] .group-data-\[variation\=\"variation1\"\]\:\[\&_\.pd-item-card-image\]\:mt-\[-72px\] .pd-item-card-image) {
  margin-top: -72px;
}

#acx-website :is(.group[data-variation="variation1"] .group-data-\[variation\=\"variation1\"\]\:\[\&_\.pd-item-card-image\]\:w-full .pd-item-card-image) {
  width: 100%;
}

@media (min-width: 1024px) {
  #acx-website :is(.lg\:\[\&_\.pd-item-card-image\]\:left-\[-170px\] .pd-item-card-image) {
    left: -170px;
  }
  #acx-website :is(.group[data-variation="variation1"] .lg\:group-data-\[variation\=\"variation1\"\]\:\[\&_\.pd-item-card-image\]\:left-\[152px\] .pd-item-card-image) {
    left: 152px;
  }
  #acx-website :is(.lg\:\[\&_data-\[no-margin\=\"false\"\]\]\:p-0 data-[no-margin="false"]) {
    padding: 0px;
  }
}

#acx-website :is(.\[\&_div\]\:bg-\[\#c0cbd8\] div) {
  --tw-bg-opacity: 1;
  background-color: rgb(192 203 216 / var(--tw-bg-opacity, 1));
}

#acx-website :is(.\[\&_div\]\:font-ultramarine div) {
  font-family: var(--font-ultramarine);
}

#acx-website :is(.\[\&_div\]\:data-\[page-main-variation\=\"website-tenant-1\"\]\:text-\[\#008000\][data-page-main-variation="website-tenant-1"] div) {
  --tw-text-opacity: 1;
  color: rgb(0 128 0 / var(--tw-text-opacity, 1));
}

#acx-website :is(.\[\&_img\]\:absolute img) {
  position: absolute;
}

#acx-website :is(.\[\&_img\]\:inset-0 img) {
  inset: 0px;
}

#acx-website :is(.\[\&_img\]\:z-\[1\] img) {
  z-index: 1;
}

#acx-website :is(.\[\&_img\]\:m-auto img) {
  margin: auto;
}

#acx-website :is(.\[\&_img\]\:box-border img) {
  box-sizing: border-box;
}

#acx-website :is(.\[\&_img\]\:block img) {
  display: block;
}

#acx-website :is(.\[\&_img\]\:h-0 img) {
  height: 0px;
}

#acx-website :is(.\[\&_img\]\:h-auto img) {
  height: auto;
}

#acx-website :is(.\[\&_img\]\:max-h-full img) {
  max-height: 100%;
}

#acx-website :is(.\[\&_img\]\:min-h-full img) {
  min-height: 100%;
}

#acx-website :is(.\[\&_img\]\:w-0 img) {
  width: 0px;
}

#acx-website :is(.\[\&_img\]\:min-w-full img) {
  min-width: 100%;
}

#acx-website :is(.\[\&_img\]\:max-w-full img) {
  max-width: 100%;
}

#acx-website :is(.\[\&_img\]\:rounded-\[10px\] img) {
  border-radius: 10px;
}

#acx-website :is(.\[\&_img\]\:rounded-\[12px\] img) {
  border-radius: 12px;
}

#acx-website :is(.\[\&_img\]\:border-\[none\] img) {
  border-color: none;
}

#acx-website :is(.\[\&_img\]\:object-cover img) {
  -o-object-fit: cover;
     object-fit: cover;
}

#acx-website :is(.\[\&_img\]\:object-\[center_center\] img) {
  -o-object-position: center center;
     object-position: center center;
}

#acx-website :is(.\[\&_img\]\:p-0 img) {
  padding: 0px;
}

#acx-website :is(.\[\&_li\]\:pb-2 li) {
  padding-bottom: 0.5rem;
}

#acx-website :is(.\[\&_li\]\:\[\&_div\]\:flex-nowrap div li) {
  flex-wrap: nowrap;
}

#acx-website :is(.\[\&_p\]\:pl-2 p) {
  padding-left: 0.5rem;
}

@media (min-width: 1024px) {
  #acx-website :is(.lg\:\[\&_pd-item-brand\]\:pr-\[50px\] pd-item-brand) {
    padding-right: 50px;
  }
}

@media (min-width: 1280px) {
  #acx-website :is(.xl\:\[\&_pd-item-brand\]\:px-0 pd-item-brand) {
    padding-left: 0px;
    padding-right: 0px;
  }
}

@media (min-width: 1024px) {
  #acx-website :is(.lg\:\[\&_pd-item-panel-left\]\:\[\&_pd-item-brand\]\:pl-\[50px\] pd-item-brand pd-item-panel-left) {
    padding-left: 50px;
  }
  #acx-website :is(.lg\:\[\&_pd-item-panel-left\]\:\[\&_pd-item-brand\]\:pr-\[0px\] pd-item-brand pd-item-panel-left) {
    padding-right: 0px;
  }
}

@media (min-width: 1280px) {
  #acx-website :is(.xl\:\[\&_pd-item-panel-left\]\:\[\&_pd-item-brand\]\:px-0 pd-item-brand pd-item-panel-left) {
    padding-left: 0px;
    padding-right: 0px;
  }
}

#acx-website :is(.\[\&_span\]\:absolute span) {
  position: absolute;
}

#acx-website :is(.\[\&_span\]\:inset-0 span) {
  inset: 0px;
}

#acx-website :is(.\[\&_span\]\:m-0 span) {
  margin: 0px;
}

#acx-website :is(.\[\&_span\]\:box-border span) {
  box-sizing: border-box;
}

#acx-website :is(.\[\&_span\]\:block span) {
  display: block;
}

#acx-website :is(.\[\&_span\]\:h-\[initial\] span) {
  height: initial;
}

#acx-website :is(.\[\&_span\]\:w-\[initial\] span) {
  width: initial;
}

#acx-website :is(.\[\&_span\]\:overflow-hidden span) {
  overflow: hidden;
}

#acx-website :is(.\[\&_span\]\:border-0 span) {
  border-width: 0px;
}

#acx-website :is(.\[\&_span\]\:p-0 span) {
  padding: 0px;
}

#acx-website :is(.\[\&_span\]\:opacity-100 span) {
  opacity: 1;
}

#acx-website :is(.\[\&_strong\]\:font-medium strong) {
  font-weight: 500;
}

#acx-website :is(.\[\&_text\]\:font-ultramarine text) {
  font-family: var(--font-ultramarine);
}

#acx-website :is(*>.\[\*\>\&\]\:p-0) {
  padding: 0px;
}

#acx-website :is(.pd-item-no-secondary-image .\[\.pd-item-no-secondary-image_\&\]\:px-0) {
  padding-left: 0px;
  padding-right: 0px;
}

#acx-website :is(.pd-item-no-secondary-image .\[\.pd-item-no-secondary-image_\&\]\:py-8) {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

#acx-website :is(.secondary .\[\.secondary_\&\]\:left-\[-152px\]) {
  left: -152px;
}

#acx-website :is(.secondary .\[\.secondary_\&\]\:left-\[-170px\]) {
  left: -170px;
}

#acx-website :is(.secondary .data-\[variation\=\"variation1\"\]\:\[\.secondary_\&\]\:left-\[-152px\][data-variation="variation1"]) {
  left: -152px;
}

#acx-website :is(data-[variation1] .\[data-\[variation1\]_\&\]\:px-0) {
  padding-left: 0px;
  padding-right: 0px;
}

#acx-website :is(data-[variation1] .\[data-\[variation1\]_\&\]\:pt-4) {
  padding-top: 1rem;
}

#acx-website :is(data-[variation="variation1"] .\[data-\[variation\=\"variation1\"\]_\&\]\:pl-0) {
  padding-left: 0px;
}

#acx-website :is(data-[variation="variation1"] .\[data-\[variation\=\"variation1\"\]_\&\]\:\[\&_p\]\:w-full p) {
  width: 100%;
}

#acx-website :is(data-[variation="variation1"] .\[data-\[variation\=\"variation1\"\]_\&\]\:\[\&_p\]\:text-left p) {
  text-align: left;
}

#acx-website :is(section-breakline container-grid .\[section-breakline_\&\]\:\[container-grid_\&\]\:\[\&_h2\]\:p-0 h2) {
  padding: 0px;
}

@media (min-width: 1024px) {
  #acx-website :is(section .lg\:\[section_\&\]\:max-w-full) {
    max-width: 100%;
  }
}

.section-title{font-weight:500;font-size:1.4375rem;letter-spacing:normal}@media screen and (min-width: 1024px){.section-title{font-size:2.3125rem;letter-spacing:-0.02em}}
@font-face {
font-family: '__ultramarine_6f79cd';
src: url(/_next/static/media/b8e5f26b94190d86-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 700;
font-style: normal;
}

@font-face {
font-family: '__ultramarine_6f79cd';
src: url(/_next/static/media/5722393e1cbb783a-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 700;
font-style: italic;
}

@font-face {
font-family: '__ultramarine_6f79cd';
src: url(/_next/static/media/fbcd03a73aca8297-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: italic;
}

@font-face {
font-family: '__ultramarine_6f79cd';
src: url(/_next/static/media/210166799ccc20f9-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: '__ultramarine_6f79cd';
src: url(/_next/static/media/a046e5d31c9b0506-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 500;
font-style: italic;
}

@font-face {
font-family: '__ultramarine_6f79cd';
src: url(/_next/static/media/9af660a4f7ed6cb0-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: normal;
}@font-face {font-family: '__ultramarine_Fallback_6f79cd';src: local("Arial");ascent-override: 93.12%;descent-override: 29.10%;line-gap-override: 0.00%;size-adjust: 106.53%
}.__className_6f79cd {font-family: '__ultramarine_6f79cd', '__ultramarine_Fallback_6f79cd'
}.__variable_6f79cd {--font-ultramarine: '__ultramarine_6f79cd', '__ultramarine_Fallback_6f79cd'
}


