@use 'styles/variables' as var;
@use 'styles/functions' as fn;

/*
Prototype: Responsive Ultramarine CSS only solution

This is an example of how responsive Typography styling could be done without relying on Ascend
and `isMobile` to be responsive. This is more "old school" but has many benefits, but the biggest
one being that the browser viewport is in total control of what font styles will be visible on load
without waiting for all the js to be ready

Note:
- This is a global css file, not a SCSS module. It is available to components at a the _app level

Only need:
```
<Component className="section-title" />
```

vs Module SCSS:
```
import styles from './Component.module.scss'
<Component className={styles['section-title'] />
```
*/

.section-title {
  font-weight: 500;
  font-size: 1.4375rem;
  letter-spacing: normal;
}

@media screen and (min-width: 1024px) {
  .section-title {
    font-size: 2.3125rem;
    letter-spacing: -0.02em;
  }
}
