/* stylelint-disable property-no-vendor-prefix, scss/at-rule-no-unknown */
@tailwind base;
@tailwind components;
@tailwind utilities;

/**
// overwrite transitions for the home-hero carousel to avoid bugs on safari.
// can't use inline CSS with vanilla js because -webkit and -moz don't quite work on safari with this approach
*/

.main-home-hero-section ul,
.main-home-hero-section li {
  -webkit-transition: all 1.2s ease !important;
  -moz-transition: all 1.2s ease !important;
  -o-transition: all 1.2s ease !important;
  transition: all 1.2s ease !important;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

body {
  color: #1d252f; /* var.$color-content-primary; */
  font-family: var(--font-ultramarine);
}

/* bold */
/* @font-face {
  font-family: Ultramarine;
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('Ultramarine'), url('/next-assets/fonts/Ultramarine-Bold.woff2') format('woff2'),
    local('Ultramarine'), url('/next-assets/fonts/Ultramarine-Bold.woff') format('woff');
} */

/* bold italic */
/* @font-face {
  font-family: Ultramarine;
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: local('Ultramarine'), url('/next-assets/fonts/Ultramarine-BoldItalic.woff2') format('woff2'),
    local('Ultramarine'), url('/next-assets/fonts/Ultramarine-BoldItalic.woff') format('woff');
} */

/* italic */
/* @font-face {
  font-family: Ultramarine;
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: local('Ultramarine'), url('/next-assets/fonts/Ultramarine-Italic.woff2') format('woff2'),
    url('/next-assets/fonts/Ultramarine-Italic.woff') format('woff');
} */

/* medium */
/* @font-face {
  font-family: Ultramarine;
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local('Ultramarine'), url('/next-assets/fonts/Ultramarine-Medium.woff2') format('woff2'),
    local('Ultramarine'), url('/next-assets/fonts/Ultramarine-Medium.woff') format('woff');
} */

/* medium italic */
/* @font-face {
  font-family: Ultramarine;
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: local('Ultramarine'),
    url('/next-assets/fonts/Ultramarine-MediumItalic.woff2') format('woff2'), local('Ultramarine'),
    url('/next-assets/fonts/Ultramarine-MediumItalic.woff') format('woff');
} */

/* regular */
/* @font-face {
  font-family: Ultramarine;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Ultramarine'), url('/next-assets/fonts/Ultramarine-Regular.woff2') format('woff2'),
    local('Ultramarine'), url('/next-assets/fonts/Ultramarine-Regular.woff') format('woff');
} */

/* footer { */

/* TODO: Update Footer component to support custom styling passing a className to apply those styles. */
footer a[data-testid='achieve-logo'] {
  width: 66px !important;
  height: 54px !important;
  overflow: hidden;
}

footer a[data-testid='achieve-logo'] img {
  width: 215px !important;
  min-width: inherit !important;
  max-width: inherit !important;
}

/* stylelint-enable property-no-vendor-prefix */

#tcb-container {
  height: 340px;
}

@media screen and (min-width: 480px) {
  #tcb-container {
    height: 228px;
  }
}

@media screen and (min-width: 768px) {
  #tcb-container {
    height: 160px;
  }
}

@media screen and (min-width: 1024px) {
  #tcb-container {
    height: 130px;
  }
}
